<template>
  <div class="p-4 md:p-8">
    <div class="md:flex w-full justify-center">
      <div class="w-full flex flex-col md:w-72 lg:mx-1 mb-2 lg:mb-0">
        <input
          type="file"
          class="hidden"
          :disabled="isNew"
          accept=".jpeg,.jpg,.png"
          multiple="false"
          ref="thumbnailFileInput"
        />
        <img
          :class="{ 'cursor-pointer': !isNew }"
          class="w-full rounded-2xl mb-2 object-cover max-h-64"
          :src="thumbnailSrc"
          @click="clickThumbnail"
          v-if="thumbnailSrc"
        />
        <img
          :class="{ 'cursor-pointer': !isNew }"
          class="w-full rounded-2xl mb-2 object-cover"
          src="@/assets/vehicle-placeholder-1.svg"
          @click="clickThumbnail"
          v-else-if="company.type === carAccountType"
        />
        <img
          :class="{ 'cursor-pointer': !isNew }"
          class="w-full rounded-2xl mb-2 object-cover"
          src="@/assets/truck-placeholder.svg"
          @click="clickThumbnail"
          v-else-if="company.type === truckingAccountType"
        />
        <div class="w-full flex flex-col">
          <button
            @click="vinLookup"
            type="button"
            class="transition mb-1 py-1.5 bg-lula-gradient text-white hover:bg-lula-gradient-alt"
          >
            Lookup VIN
          </button>
          <button
            @click="generateVehicleDocuments"
            type="button"
            class="transition mb-1 py-1.5 bg-lula-gradient text-white hover:bg-lula-gradient-alt"
            v-if="company.type === carAccountType && !isNew"
          >
            Generate Certificates
          </button>
          <button
            @click="goToVehicleDetail"
            type="button"
            class="transition mb-1 py-1.5 bg-lula-gradient text-white hover:bg-lula-gradient-alt"
          >
            Go To Underwriting Trail
          </button>
        </div>
      </div>
      <div class="flex-grow md:max-w-2xl">
        <div class="mx-1 bg-white rounded-2xl p-4">
          <div class="grid sm:grid-cols-3 md:grid-cols-1 lg:grid-cols-3">
            <button
              class="m-px text-center font-bold text-gray-800 hover:bg-gray-100"
              :class="{ 'bg-gray-100': vehicleTab === 'details' }"
              @click="vehicleTab = 'details'"
            >
              Details
            </button>
            <button
              class="m-px text-center font-bold text-gray-800 hover:bg-gray-100"
              :class="{ 'bg-gray-100': vehicleTab === 'vehicle' }"
              @click="vehicleTab = 'vehicle'"
            >
              Vehicle
            </button>
            <button
              class="m-px text-center font-bold text-gray-800 hover:bg-gray-100"
              :class="{ 'bg-gray-100': vehicleTab === 'registration' }"
              @click="vehicleTab = 'registration'"
            >
              Registration
            </button>
          </div>
          <form @submit.prevent="saveVehicle">
            <div
              v-for="section in getSectionsForTab(details, vehicleTab)"
              :key="section"
            >
              <div v-if="section !== 'default'">
                <h3 class="font-bold text-gray-800 text-lg">
                  {{ section }}
                </h3>
                <hr class="my-2" />
              </div>
              <div class="md:grid lg:grid-cols-2 md:mb-2">
                <div
                  class="p-2"
                  :class="{
                    'md:col-span-2': detail.key === 'financeCompanyApplicable',
                    'md:col-span-2': detail.key === 'insuranceCriteriaReason',
                    hidden:
                      detail.key === 'insuranceCriteriaReason' &&
                      vehicle.insuranceCriteriaStatus == 'Approved',
                    hidden:
                      detail.key === 'statedMileageRange' &&
                      !vehicle.statedMileageRange,
                  }"
                  v-for="detail in getDetailsForSection(
                    details,
                    vehicleTab,
                    section,
                  )"
                  :key="detail.key"
                >
                  <label class="font-bold">{{ detail.name }}</label>
                  <br />
                  <router-link
                    v-if="detail.key === 'companyName'"
                    :to="`/company/${company.id}`"
                    className="block bg-gray-100 w-full py-1 px-2 rounded-lg cursor-pointer hover:bg-gray-200"
                  >
                    {{ company.businessLegalName }}
                  </router-link>
                  <select
                    v-else-if="detail.key === 'registrantType'"
                    v-model="vehicle[detail.key]"
                    @change="onChangeRegistrantType($event)"
                    required
                  >
                    <option
                      v-if="vehicle[detail.key] === undefined"
                      disabled
                      :value="undefined"
                    >
                      Select one
                    </option>
                    <option value="Person">Person</option>
                    <option value="Business">Business</option>
                  </select>
                  <div v-else-if="detail.key === 'statedMileageRange'">
                    <select
                      v-if="
                        mileageRangeOptions.some(
                          (item) => item.value === vehicle.statedMileageRange,
                        )
                      "
                      v-model="vehicle[detail.key]"
                    >
                      <option
                        v-if="!vehicle[detail.key]"
                        disabled
                        :value="vehicle[detail.key]"
                      >
                        Select Mileage Range
                      </option>
                      <option
                        v-for="mileage in mileageRangeOptions"
                        :value="mileage.value"
                        :key="mileage"
                      >
                        {{ mileage.label }}
                      </option>
                    </select>
                    <input v-else type="number" v-model="vehicle[detail.key]" />
                  </div>

                  <select
                    v-else-if="detail.key === 'registrantGovernmentIdType'"
                    v-model="vehicle[detail.key]"
                    required
                  >
                    <option disabled value="">Select one</option>
                    <option value="DriverLicense">Driver License</option>
                    <option value="FEIN">EIN / FEIN</option>
                  </select>
                  <input
                    v-else-if="detail.key === 'registrantGovernmentId'"
                    v-model="vehicle[detail.key]"
                    @blur="formatRegistrantGovernmentId"
                    type="text"
                  />
                  <input
                    v-else-if="detail.key === 'registrantFirstName'"
                    v-model="vehicle[detail.key]"
                    :disabled="!isPerson"
                    type="text"
                  />
                  <input
                    v-else-if="detail.key === 'registrantMiddleName'"
                    v-model="vehicle[detail.key]"
                    :disabled="!isPerson"
                    type="text"
                  />
                  <input
                    v-else-if="detail.key === 'registrantLastName'"
                    v-model="vehicle[detail.key]"
                    :disabled="!isPerson"
                    type="text"
                  />
                  <input
                    v-else-if="detail.key === 'registrationName'"
                    v-model="vehicle[detail.key]"
                    :disabled="!isBusiness"
                    type="text"
                  />
                  <input
                    type="text"
                    minlength="17"
                    maxlength="17"
                    required
                    :class="{
                      'bg-lula-light hover:bg-lula-light':
                        vehicle.vin?.length !== 17,
                    }"
                    v-model="vehicle[detail.key]"
                    v-else-if="detail.key === 'vin'"
                  />
                  <select
                    @change="insuranceCriteriaChange"
                    v-else-if="detail.key === 'insuranceCriteriaStatus'"
                    v-model="vehicle[detail.key]"
                  >
                    <option value="Under Review">Under Review</option>
                    <option value="Approved">Approved</option>
                    <option value="Declined">Declined</option>
                  </select>
                  <select
                    :disabled="
                      !getLaunchDarklyFlag('admin.policy') ||
                      company.status === 'Inactive' ||
                      vehicle.insuranceCriteriaStatus === 'Under Review'
                    "
                    v-else-if="detail.key === 'status'"
                    v-model="vehicle[detail.key]"
                  >
                    <option value="Requested" disabled>Requested</option>
                    <option
                      value="Pending"
                      v-if="
                        company.status === 'Onboarding' ||
                        company.status === 'Hold'
                      "
                    >
                      Pending
                    </option>
                    <option value="Inactive">Inactive</option>
                    <option
                      value="Active"
                      v-if="
                        company.status !== 'Onboarding' &&
                        company.status !== 'Hold' &&
                        vehicle.insuranceCriteriaStatus === 'Approved'
                      "
                    >
                      Active
                    </option>
                    <option
                      value="Archive"
                      v-if="
                        vehicle.status !== 'Active' &&
                        vehicle.insuranceCriteriaStatus === 'Approved'
                      "
                    >
                      Archive
                    </option>
                  </select>
                  <select
                    v-else-if="detail.key === 'financeCompanyApplicable'"
                    v-model="vehicle[detail.key]"
                  >
                    <option :value="true">Vehicle is Financed</option>
                    <option :value="false">Vehicle is not Financed</option>
                  </select>
                  <div
                    class="flex items-center"
                    v-else-if="detail.key === 'statedValue'"
                  >
                    <span class="mr-2 font-bold">$</span>
                    <input
                      type="number"
                      min="0"
                      step="1"
                      v-model="vehicle[detail.key]"
                    />
                  </div>
                  <state-select
                    v-else-if="detail.key === 'vehicleRegistrationState'"
                    @change="onChangeVehicleRegistrationState($event)"
                    v-model="vehicle[detail.key]"
                    :states="vehicleRegistrationStates"
                  />
                  <select
                    v-else-if="detail.key === 'registrationClass'"
                    v-model="vehicle[detail.key]"
                  >
                    <option
                      v-if="
                        vehicle.vehicleRegistrationState !== 'NY' &&
                        !vehicle[detail.key]
                      "
                      :value="vehicle[detail.key]"
                    >
                      Not Applicable
                    </option>
                    <option
                      v-if="
                        vehicle.vehicleRegistrationState === 'NY' &&
                        !vehicle[detail.key]
                      "
                      :value="vehicle[detail.key]"
                    >
                      Select One
                    </option>
                    <option
                      v-if="vehicle.vehicleRegistrationState === 'NY'"
                      value="pas"
                    >
                      Passenger (PAS)
                    </option>
                    <option
                      v-if="vehicle.vehicleRegistrationState === 'NY'"
                      value="oms"
                    >
                      For Hire (OMS)
                    </option>
                  </select>
                  <state-select
                    v-else-if="detail.type === 'state'"
                    v-model="vehicle[detail.key]"
                  />
                  <input
                    v-else-if="detail.type === 'mileage'"
                    v-model.number="vehicle[mileage]"
                    pattern="^[0-9]*$"
                    oninvalid="setCustomValidity('Mileage must only contain numbers')"
                    oninput="setCustomValidity('')"
                  />
                  <input
                    v-else
                    :type="detail.type"
                    v-model="vehicle[detail.key]"
                    :disabled="detail.disabled === true"
                    :min="detail.min"
                    :max="detail.max"
                  />
                </div>
              </div>
              <div
                class="flex justify-end mx-1"
                v-if="
                  section === 'Registration Details' ||
                  section === 'Finance Company' ||
                  section === 'Lot Information'
                "
              >
                <span
                  @click="addCompanyInformation(section)"
                  class="font-bold text-gray-900 cursor-pointer hover:underline"
                >
                  Add company information
                </span>
              </div>
            </div>
            <div
              class="p-2 mb-2 bg-gray-100 rounded-xl"
              v-if="vehicleTab === 'details' && company.enableOverride"
            >
              <label class="font-bold mx-2">Price Override</label>
              <br />
              <div class="flex items-center p-2">
                <span class="font-bold mr-2">$</span>
                <input
                  class="bg-white"
                  type="number"
                  min="0"
                  step="0.01"
                  v-model="vehicle.override"
                />
              </div>
              <div class="flex justify-end px-2">
                <span
                  class="font-bold text-gray-600 cursor-pointer hover:underline"
                  @click="vehicle.override = null"
                >
                  Remove Override
                </span>
              </div>
            </div>
            <div class="flex flex-col md:flex-row md:justify-end">
              <button
                type="button"
                @click="$router.go(-1)"
                class="bg-gray-100 hover:bg-gray-200 my-1 md:mr-1 md:my-0"
              >
                Cancel
              </button>
              <button
                type="submit"
                class="bg-lula-gradient text-white my-1 hover:bg-lula-gradient-alt md:mr-1 md:my-0"
              >
                Save Vehicle
              </button>
            </div>
          </form>
        </div>
        <div class="px-2 flex justify-end" v-if="!isNew">
          <span
            class="font-bold text-gray-600 cursor-pointer hover:underline dark:text-white"
            @click="copyCustomerLink"
          >
            Copy customer link
          </span>
        </div>
      </div>
    </div>
    <hr class="m-4" />

    <!-- off-rent mileage -->
    <div v-if="company.type === carAccountType && offRent.isFeatureEnabled">
      <h2 class="text-xl font-bold text-gray-800 mb-4 md:text-2xl">
        Off-Rent Mileage
      </h2>
      <div
        class="flex md:flex-row flex-col justify-between items-center bg-white p-4 rounded-xl shadow-lg"
      >
        <div class="w-full md:w-3/4 flex flex-col md:mr-4">
          <div class="flex md:flex-row flex-col">
            <div class="relative w-full md:w-1/2 pb-4 mr-4">
              <label
                class="required text-base font-bold text-center text-gray-800 block"
              >
                VIN
              </label>
              <input
                v-model.trim="v$.vin.$model"
                type="text"
                minlength="17"
                maxlength="17"
                class="mt-1"
                placeholder="Enter the VIN of the vehicle"
                :class="{
                  'bg-lula-light hover:bg-lula-light': v$.vin.$invalid,
                }"
                required
                readonly
              />
              <span
                class="text-xs pl-4 absolute left-0 bottom-0"
                v-if="v$.vin.required.$invalid"
              >
                The value is required
              </span>
              <span
                class="text-xs pl-4 absolute left-0 bottom-0"
                v-if="v$.vin.minLength.$invalid || v$.vin.maxLength.$invalid"
              >
                The value should be a valid vin
              </span>
            </div>

            <div class="relative w-full md:w-1/2 pb-4">
              <label
                class="required text-base font-bold text-gray-800 text-center block"
              >
                Timezone
              </label>
              <select
                name="timezone-select"
                class="mt-1"
                :class="{
                  'bg-lula-light hover:bg-lula-light': v$.timezone.$invalid,
                }"
                id="timezone-select"
                v-model.trim="v$.timezone.$model"
              >
                <option v-for="zone in timezones" :value="zone" :key="zone">
                  {{ zone }}
                </option>
              </select>
              <span
                class="text-xs pl-4 absolute left-0 bottom-0"
                v-if="v$.timezone.required.$invalid"
              >
                The value is required
              </span>
            </div>
          </div>

          <div class="flex md:flex-row flex-col">
            <div class="relative w-full md:w-1/2 pb-4 mr-4">
              <label
                class="required text-base font-bold text-gray-800 text-center block"
              >
                Start Date
              </label>
              <date-time-picker
                class="w-full rounded-lg text-gray-700 mt-1"
                :input-class-name="
                  v$.startDate.$invalid
                    ? 'border-none bg-lula-light hover:bg-lula-light'
                    : 'border-none bg-gray-100'
                "
                :enable-time-picker="true"
                format="MM-dd-yyyy HH:mm"
                autocomplete="on"
                show-now-button
                text-input
                required
                :timezone="v$.timezone.$model"
                :clearable="true"
                v-model.trim="v$.startDate.$model"
              />
              <span
                class="text-xs pl-4 absolute left-0 bottom-0"
                v-if="v$.startDate.required.$invalid"
              >
                The value is required
              </span>
            </div>

            <div class="relative w-full md:w-1/2 pb-4">
              <label
                class="required text-base font-bold text-gray-800 text-center block"
              >
                End Date
              </label>
              <date-time-picker
                class="w-full rounded-lg text-gray-700 mt-1"
                :input-class-name="
                  v$.endDate.$invalid
                    ? 'border-none bg-lula-light hover:bg-lula-light'
                    : 'border-none bg-gray-100'
                "
                :enable-time-picker="true"
                format="MM-dd-yyyy HH:mm"
                autocomplete="on"
                show-now-button
                text-input
                required
                :timezone="v$.timezone.$model"
                :clearable="true"
                v-model.trim="v$.endDate.$model"
              />
              <span
                class="text-xs pl-4 absolute left-0 bottom-0"
                v-if="v$.endDate.required.$invalid"
              >
                The value is required
              </span>
            </div>
          </div>

          <div class="flex md:flex-row flex-col mt-1">
            <div
              class="w-full md:w-1/2 mr-4 ml-1 text-sm text-gray-800 md:text-base"
            >
              <span class="font-bold">Note:</span> Mileage displayed is not
              real-time and is delayed at least 24 hours.
            </div>
            <div class="w-full md:w-1/2 text-right">
              <button
                @click="calculateOffRentMileage"
                :disabled="!isOffRentInputValid || offRent.isLoading"
                class="w-full md:w-2/5 mt-3 md:mr-1 md:mt-0"
                :class="{
                  'text-white bg-lula-gradient hover:bg-lula-gradient-alt':
                    isOffRentInputValid && !offRent.isLoading,
                  'cursor-default  bg-gray-300 text-gray-500':
                    !isOffRentInputValid || offRent.isLoading,
                }"
              >
                Calculate
              </button>
            </div>
          </div>
        </div>
        <div
          class="w-full md:w-1/4 flex flex-col rounded-xl shadow border md:mt-0 m-1 mt-4 relative"
        >
          <div class="h-1/2 mt-4">
            <div class="text-center font-bold">Miles Used:</div>
            <div class="text-center mt-1">
              {{ getMilesMessage(offRent, offRent.milesUsed) }}
            </div>
          </div>
          <div class="h-1/2 my-4">
            <div class="text-center font-bold">Miles Left:</div>
            <div
              class="text-center mt-1"
              :class="{ 'text-red-500': offRent.isReachedLimit }"
            >
              {{ getMilesMessage(offRent, offRent.milesLeft) }}
            </div>
          </div>
          <div
            v-tooltip="{
              content:
                'Off-Rent Mileage calculated using data from telematics</br>devices and Car Sharing platform reservations',
              html: true,
            }"
            class="absolute top-2 right-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="
                  M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1
                  1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113
                  8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2
                  0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0
                  100-2 1 1 0 000 2z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white rounded-lg py-4 px-8 mb-4 mt-8" v-if="!isNew">
      <h2 class="text-gray-800 font-bold text-2xl pb-2">Comments</h2>
      <textarea
        rows="5"
        :disabled="!editComments"
        v-model="vehicle.comments"
        class="rounded"
      />
      <div class="w-full flex justify-end">
        <span
          class="text-gray-600 font-bold cursor-pointer hover:underline"
          @click="saveComments"
          v-if="editComments"
        >
          Save Comments
        </span>
        <span
          class="text-gray-600 font-bold cursor-pointer hover:underline"
          @click="editComments = true"
          v-else
        >
          Edit Comments
        </span>
      </div>
    </div>
    <div v-if="!isNew">
      <div class="mb-4" v-if="company.type === carAccountType">
        <div class="flex justify-between items-center mb-2">
          <h2 class="font-bold text-gray-800 text-2xl dark:text-white">
            Vehicle Documents
          </h2>
          <input
            type="file"
            ref="vehicleDocumentUpload"
            class="hidden"
            accept="application/pdf, image/jpeg, image/png,"
            multiple
          />
          <button
            class="hidden bg-lula-gradient text-white hover:bg-lula-gradient-alt md:block"
            @click="uploadVehicleDocument"
          >
            Upload Document
          </button>
        </div>
        <div>
          <div
            class="bg-white rounded-lg text-gray-800 p-4 my-1"
            :class="{ 'bg-lula-light': doc.customMetadata?.active === 'true' }"
            v-for="doc in documents"
            :key="doc.fullPath"
          >
            <div class="flex justify-between">
              <div>
                <span
                  class="text-gray-800 text-base md:text-lg font-bold cursor-pointer hover:underline"
                  @click="selectVehicleDocument(doc)"
                >
                  {{ doc.name }}
                </span>
                <br />
                <span class="text-base md:text-xl">{{ doc.updated }}</span>
              </div>
              <div>
                <div class="flex justify-end mb-2">
                  <div v-if="company.type === carAccountType">
                    <span
                      class="font-bold mx-2 cursor-pointer hover:underline"
                      v-if="doc.customMetadata?.active === 'true'"
                      @click="editVehicleDocument(doc, 'deactivate')"
                    >
                      Deactivate
                    </span>
                    <span
                      class="font-bold mx-2 cursor-pointer hover:underline"
                      v-else
                      @click="editVehicleDocument(doc, 'activate')"
                    >
                      Activate
                    </span>
                  </div>
                  <span
                    class="font-bold mx-2 cursor-pointer hover:underline"
                    @click="editVehicleDocument(doc, 'archive')"
                  >
                    Archive
                  </span>
                </div>
                <select
                  class="hidden md:block"
                  :value="doc.customMetadata?.tag"
                  @change="editVehicleDocument(doc, 'tag', $event)"
                  v-if="company.type === carAccountType"
                >
                  <option :value="''">No Category</option>
                  <option value="driver-license">Driver License</option>
                  <option value="vehicle-registration">
                    Vehicle Registration
                  </option>
                  <option value="proof-of-purchase">Proof of Purchase</option>
                  <option value="lease-agreement">Lease Agreement</option>
                  <option value="power-of-attorney">Power of Attorney</option>
                  <option value="insurance-card">Insurance Card</option>
                  <option value="registration-card">Registration Card</option>
                  <option value="certificate-of-insurance">
                    Certificate of Insurance
                  </option>
                  <option value="ma-rmv-form">MA RMV Form</option>
                  <option value="2a-transfer-form">2A Transfer Form</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>
            <select
              class="md:hidden mt-2"
              :value="doc.customMetadata?.tag"
              @change="editVehicleDocument(doc, 'tag', $event)"
              v-if="company.type === carAccountType"
            >
              <option :value="''">No Category</option>
              <option value="driver-license">Driver License</option>
              <option value="vehicle-registration">Vehicle Registration</option>
              <option value="proof-of-purchase">Proof of Purchase</option>
              <option value="lease-agreement">Lease Agreement</option>
              <option value="power-of-attorney">Power of Attorney</option>
              <option value="insurance-card">Insurance Card</option>
              <option value="registration-card">Registration Card</option>
              <option value="certificate-of-insurance">
                Certificate of Insurance
              </option>
              <option value="ma-rmv-form">MA RMV Form</option>
              <option value="2a-transfer-form">2A Transfer Form</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div
            class="bg-white rounded-lg text-center text-gray-600 p-4 select-none"
            v-if="documents.length < 1"
          >
            No Vehicle Documents
          </div>
        </div>
      </div>
      <div class="mb-4">
        <h2 class="text-gray-800 font-bold text-2xl pb-2 dark:text-white">
          Claims
        </h2>
        <div>
          <div ref="claimsGridRef" />
          <div
            :class="{ hidden: claimsGridLoaded }"
            class="bg-white rounded border p-4"
          >
            <div class="bg-gray-100 rounded h-48 animate-pulse" />
          </div>
        </div>
      </div>
      <div class="mb-4">
        <h2 class="text-gray-800 font-bold text-2xl pb-2 dark:text-white">
          Coverage History
        </h2>
        <!-- Adding coverage history -->
        <div
          class="bg-white rounded-lg text-gray-800 border-2 p-4 mt-1 mb-4"
          v-if="editStatusHistory.enabled"
        >
          <h3 class="font-bold text-gray-800 text-xl">
            Add new effective period
          </h3>
          <hr class="my-2" />
          <div
            class="grid grid-cols-1"
            :class="{
              'md:grid-cols-4': company.type === carAccountType,
              'md:grid-cols-3': company.type === truckingAccountType,
            }"
          >
            <div class="p-1">
              <label class="font-bold">Effective date start</label>
              <br />
              <input type="date" v-model="newSubscriptionStatus.start" />
            </div>
            <div class="p-1">
              <label class="font-bold">Effective date end</label>
              <br />
              <input type="date" v-model="newSubscriptionStatus.end" />
            </div>
            <div class="p-1" v-if="company.type === carAccountType">
              <label class="font-bold">Product</label>
              <br />
              <select v-model="newSubscriptionStatus.policy">
                <option
                  v-for="policy in policies"
                  :value="policy.entityId"
                  :key="policy.entityId"
                >
                  {{ policy.name }}
                </option>
              </select>
            </div>
            <div class="p-1">
              <label class="font-bold">Registration state</label><br />
              <state-select
                v-model="newSubscriptionStatus.vehicleRegistrationState"
                :states="vehicleRegistrationStates"
              />
            </div>
            <div class="flex justify-end col-span-full">
              <span
                @click="saveSubscriptionStatus(newSubscriptionStatus)"
                class="font-bold text-gray-600 cursor-pointer mx-2 hover:underline"
              >
                Add new period
              </span>
            </div>
          </div>
        </div>
        <!-- No history -->
        <div
          class="bg-white rounded-lg text-center text-gray-600 p-4 select-none"
          v-if="statusHistory.length < 1"
        >
          No Subscription Status History
        </div>
        <!-- Editing coverage history -->
        <div
          class="bg-white rounded-lg text-gray-800 p-4 my-1"
          v-for="history in statusHistory"
          :key="history.id"
          v-else
        >
          <div
            class="grid grid-cols-1"
            :class="{
              'md:grid-cols-5': company.type === carAccountType,
              'md:grid-cols-3': company.type === truckingAccountType,
            }"
          >
            <div class="p-1" v-if="editStatusHistory.id === history.id">
              <label class="font-bold">Effective date start</label>
              <br />
              <input
                type="date"
                v-model="editStatusHistory.start"
                :disabled="editStatusHistory.id !== history.id"
              />
            </div>
            <div class="p-1" v-if="editStatusHistory.id === history.id">
              <label class="font-bold">Effective date end</label>
              <br />
              <input
                type="date"
                v-model="editStatusHistory.end"
                :disabled="editStatusHistory.id !== history.id"
              />
            </div>
            <div
              class="p-1"
              v-if="
                company.type === carAccountType &&
                editStatusHistory.id === history.id
              "
            >
              <label class="font-bold">Product</label>
              <br />
              <select
                :disabled="editStatusHistory.id !== history.id"
                v-model="editStatusHistory.policy"
              >
                <option
                  v-for="policy in policies"
                  :value="policy.entityId"
                  :key="policy.entityId"
                >
                  {{ policy.name }}
                </option>
              </select>
            </div>
            <div class="p-1" v-if="editStatusHistory.id === history.id">
              <label class="font-bold">Registration state</label><br />
              <state-select
                v-model="editStatusHistory.vehicleRegistrationState"
                :states="vehicleRegistrationStates"
                :disabled="editStatusHistory.id !== history.id"
              />
            </div>
            <div class="p-1" v-if="editStatusHistory.id !== history.id">
              <label class="font-bold">Effective date start</label>
              <br />
              <input
                type="date"
                :value="history.start"
                :disabled="editStatusHistory.id !== history.id"
              />
            </div>
            <div class="p-1" v-if="editStatusHistory.id !== history.id">
              <label class="font-bold">Effective date end</label>
              <br />
              <input
                type="date"
                :value="history.end"
                :disabled="editStatusHistory.id !== history.id"
              />
            </div>
            <div
              class="p-1"
              v-if="
                company.type === carAccountType &&
                editStatusHistory.id !== history.id
              "
            >
              <label class="font-bold">Product</label>
              <br />
              <input
                type="text"
                :class="{ 'cursor-pointer': history.policy }"
                @click="selectPolicy(history.policy)"
                :value="getPolicyName(history.policy)"
                :disabled="history.policy ? false : true"
              />
            </div>
            <div class="p-1" v-if="editStatusHistory.id !== history.id">
              <label class="font-bold">Registration state</label><br />
              <state-select
                v-model="history.vehicleRegistrationState"
                :states="vehicleRegistrationStates"
                :disabled="editStatusHistory.id !== history.id"
              />
            </div>
            <!-- Policy Number from policy service -->
            <div class="p-1">
              <label class="font-bold">Policy ID</label><br />
              <input class="text-gray-400" :value="history.policyID" disabled />
            </div>
            <span class="mx-2 text-gray-400"
              >{{ history.id.toUpperCase() }} -
              {{ history.vehicleRegistrationState }}</span
            >
            <div
              class="flex justify-end"
              :class="{ 'md:col-span-3': company.type === carAccountType }"
              v-if="editStatusHistory.enabled"
            >
              <span
                class="font-bold text-gray-600 cursor-pointer mx-2 hover:underline"
                @click="saveSubscriptionStatus(editStatusHistory)"
                v-if="editStatusHistory.id === history.id"
              >
                Save
              </span>
              <span
                class="font-bold text-gray-600 cursor-pointer mx-2 hover:underline"
                @click="editStatusHistory = { enabled: true, ...history }"
                v-else
              >
                Edit
              </span>
              <span
                @click="removeSubscriptionStatus(history)"
                class="font-bold text-gray-600 cursor-pointer mx-2 hover:underline"
              >
                Remove
              </span>
            </div>
          </div>
        </div>
        <div
          class="flex justify-end"
          v-if="getLaunchDarklyFlag('admin.policy')"
        >
          <span
            class="mx-2 font-bold text-gray-600 cursor-pointer hover:underline dark:text-white"
            @click="
              editStatusHistory = {
                ...editStatusHistroy,
                enabled: false,
                id: null,
              }
            "
            v-if="editStatusHistory.enabled"
          >
            Disable history editing
          </span>
          <span
            class="mx-2 font-bold text-gray-600 cursor-pointer hover:underline dark:text-white"
            @click="editStatusHistory.enabled = true"
            v-else
          >
            Enable history editing
          </span>
        </div>
      </div>
      <div class="mb-4">
        <h2 class="font-bold text-2xl text-gray-800 mb-4 dark:text-white">
          Changelog
        </h2>
        <grid
          v-if="changelog.length > 0"
          :rows="changelog"
          :columns="changelogColumns"
        />
        <div
          class="bg-white rounded-lg text-center text-gray-600 p-4 select-none"
          v-else
        >
          {{
            changelogRefresh
              ? "Refreshing changelog..."
              : "No Vehicle Change History"
          }}
        </div>
        <div class="flex justify-end">
          <span
            class="font-bold text-gray-600 cursor-pointer hover:underline dark:text-white"
            @click="refreshChangelog"
          >
            Refresh Changelog
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { useRoute, useRouter } from "vue-router";
import { watch, ref, onMounted, computed, reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";
import { isValidOrReturnDescription } from "usdl-regex";
import {
  getBase64,
  getMegabytesFromBytes,
  getChangelogDescription,
  formatEin,
} from "@/utils";
import { getAccessToken } from "@/firebase";
import {
  initializeShadowDom,
  fetchFrontend,
  mountFrontend,
} from "@getaddify/lula-components";

import axios from "axios";
import moment from "moment";

const timezones = [
  "America/Los_Angeles",
  "America/Denver",
  "America/Chicago",
  "America/New_York",
  "UTC",
];

function getVehicleDetails() {
  // {
  //    name: A human-friendly name
  //    key: A unique identifier
  //    type: text | select | state - the type of input to render
  //    tab: The tab the detail appears in
  //    section: The section under the tab the detail appears in
  //    launchDarkly: An optional flag indicating which flag this field is hidden behind
  // }
  const registrantDateOfBirthValues = () => {
    const today = new Date();
    let max = new Date(today);
    max.setFullYear(max.getFullYear() - 16);
    return {
      max: max.toISOString().split("T")[0],
    };
  };
  return [
    {
      name: "VIN",
      key: "vin",
      type: "text",
      tab: "vehicle",
      section: "default",
    },
    {
      name: "Nickname",
      key: "key",
      type: "text",
      tab: "vehicle",
      section: "default",
    },
    {
      name: "Company",
      key: "companyName",
      type: "text",
      disabled: true,
      tab: "details",
      section: "default",
    },
    {
      name: "Make",
      key: "make",
      type: "text",
      disabled: true,
      tab: "vehicle",
      section: "default",
    },
    {
      name: "Model",
      key: "model",
      type: "text",
      disabled: true,
      tab: "vehicle",
      section: "default",
    },
    {
      name: "Plate",
      key: "plate",
      type: "text",
      tab: "vehicle",
      section: "default",
    },
    {
      name: "Year",
      key: "year",
      type: "text",
      disabled: true,
      tab: "vehicle",
      section: "default",
    },
    {
      name: "Stated Mileage Range",
      key: "statedMileageRange",
      type: "select",
      tab: "vehicle",
      section: "default",
    },
    {
      name: "Mileage",
      key: "mileage",
      type: "text",
      tab: "vehicle",
      section: "default",
    },
    {
      name: "Class",
      key: "class",
      type: "text",
      disabled: true,
      tab: "vehicle",
      section: "default",
    },
    {
      name: "Domicile State",
      key: "domicileState",
      type: "state",
      tab: "vehicle",
      section: "default",
    },
    {
      name: "Vehicle Registration State",
      key: "vehicleRegistrationState",
      type: "state",
      tab: "vehicle",
      section: "default",
    },
    {
      name: "Registration Class",
      key: "registrationClass",
      type: "select",
      tab: "vehicle",
      section: "default",
    },
    {
      name: "Registrant Type",
      key: "registrantType",
      type: "select",
      tab: "registration",
      section: "Registration Details",
    },
    {
      name: "Government ID Type",
      key: "registrantGovernmentIdType",
      type: "select",
      tab: "registration",
      section: "Registration Details",
    },
    {
      name: "Registrant Government ID",
      key: "registrantGovernmentId",
      type: "text",
      tab: "registration",
      section: "Registration Details",
    },
    {
      name: "Driver License State",
      key: "driverLicenseStateIssued",
      type: "state",
      tab: "registration",
      section: "Registration Details",
    },
    {
      name: "Registration Name",
      key: "registrationName",
      type: "text",
      tab: "registration",
      section: "Registration Details",
    },
    {
      name: "Registration First Name",
      key: "registrantFirstName",
      type: "text",
      tab: "registration",
      section: "Registration Details",
    },
    {
      name: "Registration Middle Name",
      key: "registrantMiddleName",
      type: "text",
      tab: "registration",
      section: "Registration Details",
    },
    {
      name: "Registration Last Name",
      key: "registrantLastName",
      type: "text",
      tab: "registration",
      section: "Registration Details",
    },
    {
      name: "Date of Birth",
      key: "registrantDateOfBirth",
      type: "date",
      tab: "registration",
      section: "Registration Details",
      max: registrantDateOfBirthValues().max,
    },
    {
      name: "Address Line 1",
      key: "registrationAddressLineOne",
      type: "text",
      tab: "registration",
      section: "Registration Details",
    },
    {
      name: "Address Line 2",
      key: "registrationAddressLineTwo",
      type: "text",
      tab: "registration",
      section: "Registration Details",
    },
    {
      name: "City",
      key: "registrationCity",
      type: "text",
      tab: "registration",
      section: "Registration Details",
    },
    {
      name: "State",
      key: "registrationState",
      type: "state",
      tab: "registration",
      section: "Registration Details",
    },
    {
      name: "Zipcode",
      key: "registrationZipcode",
      type: "text",
      tab: "registration",
      section: "Registration Details",
    },
    {
      name: "Financed",
      key: "financeCompanyApplicable",
      type: "select",
      tab: "registration",
      section: "Finance Company",
    },
    {
      name: "Company Name",
      key: "financeCompanyName",
      type: "text",
      tab: "registration",
      section: "Finance Company",
    },
    {
      name: "Address Line 1",
      key: "financeCompanyAddressLineOne",
      type: "text",
      tab: "registration",
      section: "Finance Company",
    },
    {
      name: "Address Line 2",
      key: "financeCompanyAddressLineTwo",
      type: "text",
      tab: "registration",
      section: "Finance Company",
    },
    {
      name: "City",
      key: "financeCompanyCity",
      type: "text",
      tab: "registration",
      section: "Finance Company",
    },
    {
      name: "State",
      key: "financeCompanyState",
      type: "state",
      tab: "registration",
      section: "Finance Company",
    },
    {
      name: "Zipcode",
      key: "financeCompanyZipcode",
      type: "text",
      tab: "registration",
      section: "Finance Company",
    },
    {
      name: "Address Line 1",
      key: "lotAddressLineOne",
      type: "text",
      tab: "registration",
      section: "Lot Information",
    },
    {
      name: "Address Line 2",
      key: "lotAddressLineTwo",
      type: "text",
      tab: "registration",
      section: "Lot Information",
    },
    {
      name: "City",
      key: "lotCity",
      type: "text",
      tab: "registration",
      section: "Lot Information",
    },
    {
      name: "State",
      key: "lotState",
      type: "state",
      tab: "registration",
      section: "Lot Information",
    },
    {
      name: "Zipcode",
      key: "lotZipcode",
      type: "text",
      tab: "registration",
      section: "Lot Information",
    },
    {
      name: "Insurance Criteria Status",
      key: "insuranceCriteriaStatus",
      type: "text",
      default: "Under Review",
      tab: "details",
      section: "default",
    },
    {
      name: "Declined Reason",
      key: "insuranceCriteriaReason",
      type: "text",
      default: "",
      disabled: false,
      tab: "details",
      section: "default",
    },
    {
      name: "Subscription Status",
      key: "status",
      type: "text",
      default: "Requested",
      tab: "details",
      section: "default",
    },
    {
      name: "Modified",
      key: "modified",
      type: "text",
      disabled: true,
      tab: "details",
      section: "default",
    },
    {
      name: "Created",
      key: "created",
      type: "text",
      disabled: true,
      tab: "details",
      section: "default",
    },
  ];
}

function getExcludedFieldsForAccountType(accountType) {
  switch (accountType) {
    case process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"]: {
      return ["domicileState"];
    }
    default:
    case process.env["VUE_APP_ACCOUNT_TYPE_TRUCKING"]: {
      return ["statedValue", "vehicleRegistrationState"];
    }
  }
}

function getVehicleFromDetails(details) {
  const vehicle = {};
  details.forEach((detail) => {
    vehicle[detail.key] = detail.default || "";
  });
  return vehicle;
}

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const toast = useToast();

    const carAccountType = computed(
      () => process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"],
    );
    const truckingAccountType = computed(
      () => process.env["VUE_APP_ACCOUNT_TYPE_TRUCKING"],
    );

    // An array of { key: string, flag: string, enabled: bool } values used to store which details are enabled or disabled behind launchDarkly flags.
    const detailsLaunchDarklyFlags = ref([]);

    const isNew = computed(() => route.params.vehicleId === "new");
    const isPerson = computed(() => {
      return vehicle.value.registrantType === "Person";
    });
    const isBusiness = computed(() => {
      return (
        vehicle.value.registrantType === "Business" ||
        !vehicle.value.registrantType
      );
    });

    const vehicleTab = ref("vehicle");

    const details = getVehicleDetails();
    const vehicle = ref(getVehicleFromDetails(details));

    const company = ref({});
    const changelog = ref([]);
    const documents = ref([]);
    const statusHistory = ref([]);
    const policies = ref([]);

    const monthStartDateUtc = moment().utc().startOf("month");
    const currentDateUtc = moment().utc();
    const defaultTimezone = "UTC";
    const offRentDateTimeFormat = "YYYY-MM-DDTHH:mm:ssZ";
    const offRentMilesLimit = 300;

    const offRent = ref({
      isFeatureEnabled: false,
      vin: "",
      timezone: defaultTimezone,
      startDate: monthStartDateUtc.format(offRentDateTimeFormat).toString(),
      endDate: currentDateUtc.format(offRentDateTimeFormat).toString(),
      milesUsed: 0,
      milesLeft: 0,
      isRegistered: false,
      isReachedLimit: false,
      isInitialized: false,
      isLoading: false,
    });

    const offRentValidatableFields = reactive({
      vin: vehicle.value.vin,
      timezone: offRent.value.timezone,
      startDate: offRent.value.startDate,
      endDate: offRent.value.endDate,
    });

    function updateOffRentValidatableFields() {
      offRentValidatableFields.vin = vehicle.value.vin;
      offRentValidatableFields.timezone = offRent.value.timezone;
      offRentValidatableFields.startDate = offRent.value.startDate;
      offRentValidatableFields.endDate = offRent.value.endDate;
    }

    function updateOffRentSourceModel() {
      offRent.value.vin = offRentValidatableFields.vin;
      offRent.value.timezone = offRentValidatableFields.timezone;
      offRent.value.startDate = offRentValidatableFields.startDate;
      offRent.value.endDate = offRentValidatableFields.endDate;
    }

    function resetOffRentInputChanges() {
      v$.value.$reset();
    }

    const offRentValidationRules = computed(() => {
      return {
        vin: { required, minLength: minLength(17), maxLength: maxLength(17) },
        timezone: { required },
        startDate: { required },
        endDate: { required },
      };
    });

    const v$ = useVuelidate(offRentValidationRules, offRentValidatableFields);

    const isOffRentInputValid = computed(
      () =>
        !v$.value.vin.$invalid &&
        !v$.value.timezone.$invalid &&
        !v$.value.startDate.$invalid &&
        !v$.value.endDate.$invalid,
    );

    const isOffRentInputTouched = computed(
      () =>
        v$.value.vin.$dirty ||
        v$.value.timezone.$dirty ||
        v$.value.startDate.$dirty ||
        v$.value.endDate.$dirty,
    );

    async function processOffRentRequest(offRentRequest) {
      offRent.value.isLoading = true;

      const offRentResponse = await store.dispatch(
        "getOffRentMileage",
        offRentRequest,
      );

      if (offRentResponse.success) {
        offRent.value.milesUsed = offRentResponse.data.mileage.toFixed(1);
        const milesDiff = offRentMilesLimit - offRent.value.milesUsed;
        offRent.value.milesLeft = Math.max(0, milesDiff).toFixed(1);
        offRent.value.isReachedLimit = offRent.value.milesLeft <= 0;
        offRent.value.isRegistered = true;
        offRent.value.isInitialized = true;
      } else if (offRentResponse.statusCode === 404) {
        offRent.value.isReachedLimit = false;
        offRent.value.isRegistered = false;
        offRent.value.isInitialized = true;
      } else {
        console.error(
          "[VEHDTL] Cannot get the off-rent mileage info. Error: ",
          offRentResponse.error,
        );
      }
      offRent.value.isLoading = false;
    }

    async function initializeOffRentInfo() {
      offRent.value.isFeatureEnabled =
        company.value.type === carAccountType.value;

      if (!offRent.value.vin || !offRent.value.isFeatureEnabled) {
        return;
      }

      const offRentRequest = {
        vin: offRent.value.vin,
        startDate: offRent.value.startDate,
        endDate: offRent.value.endDate,
        tenantId: company.value.entityId,
      };

      updateOffRentValidatableFields();
      await processOffRentRequest(offRentRequest);
    }

    async function recalculateOffRentInfo(offRentInput) {
      function formatDateTime(dateTime) {
        return moment(dateTime)
          .tz(offRentInput.timezone, false)
          .format(offRentDateTimeFormat)
          .toString();
      }

      const offRentRequest = {
        vin: offRentInput.vin,
        startDate: formatDateTime(offRentInput.startDate),
        endDate: formatDateTime(offRentInput.endDate),
        tenantId: company.value.entityId,
      };

      updateOffRentSourceModel();
      resetOffRentInputChanges();
      await processOffRentRequest(offRentRequest);
    }

    onMounted(async () => {
      company.value = await store.dispatch(
        "getCompany",
        route.params.companyId,
      );
      policies.value = await store.dispatch("getSystemPolicies");
    });

    onMounted(async () => {
      const details = getVehicleDetails();

      // Initialize a map of all the launchDarkly flags seen.
      // flagsSeen maps a detail's key to its launchDarkly enabled value.
      const flagsSeen = {};
      details.forEach((detail) => {
        if (detail.launchDarkly !== undefined) {
          flagsSeen[detail.launchDarkly] = null;
        }
      });

      for (let i = 0; i < details.length; i += 1) {
        const detail = details[i];
        if (detail.launchDarkly !== undefined) {
          let enabled = false;
          if (flagsSeen[detail.launchDarkly] !== null) {
            enabled = flagsSeen[detail.launchDarkly];
          } else {
            enabled = await store.state.launchDarkly?.variation(
              detail.launchDarkly,
              false,
            );
            flagsSeen[detail.launchDarkly] = enabled;
          }

          detailsLaunchDarklyFlags.value.push({
            key: detail.key,
            flag: detail.launchDarkly,
            enabled,
          });
        }
      }
    });

    const changelogRefresh = ref(false);
    const vehicleDocumentUpload = ref(null);
    const editComments = ref(false);
    const editStatusHistory = ref({
      enabled: false,
      id: null,
      start: null,
      end: null,
    });

    const thumbnailFileInput = ref(null);
    const thumbnailSrc = ref(null);

    onMounted(() => {
      if (thumbnailFileInput.value) {
        thumbnailFileInput.value.addEventListener("change", async () => {
          const file = [...thumbnailFileInput.value.files].pop();
          if (file) {
            if (getMegabytesFromBytes(file.size) < 5) {
              const baseString = await getBase64(file);
              thumbnailSrc.value = baseString;
              toast("Saving thumbnail.");
              try {
                vehicle.value = await store.dispatch("saveVehicleThumbnail", {
                  ...vehicle.value,
                  companyId: company.value.id,
                  thumbnail: thumbnailSrc.value,
                });
                store.dispatch("getVehicles");
                toast.clear();
                toast("Thumbnail saved.");
              } catch (err) {
                const matchExp =
                  /The value of property "thumbnail" is longer than \d+ bytes/;
                if (err.message.match(matchExp)) {
                  toast.clear();
                  toast("File too large!");
                } else {
                  toast.clear();
                  toast("Failed to upload file.");
                  throw err;
                }
              }
            } else {
              toast.clear();
              toast("File too large!");
            }
            thumbnailFileInput.value.value = "";
          }
        });
      }
    });

    const newSubscriptionStatus = ref({
      start: null,
      end: null,
      vehicleRegistrationState: null,
    });

    function refreshDocuments() {
      store
        .dispatch("getVehicleDocumentList", {
          accountId: route.params.companyId,
          vehicleId: route.params.vehicleId,
        })
        .then((vDocs) => (documents.value = vDocs));
    }

    async function generateDoc(docType) {
      try {
        await store.dispatch("generateVehicleDocuments", {
          accountId: company.value.id,
          vehicleId: vehicle.value.id,
          documentType: docType,
        });
      } catch (err) {
        toast.clear();
        toast.error(`Failed to create document type ${docType}`);
      }
    }
    onMounted(async () => {
      refreshDocuments();
      await new Promise((resolve) => {
        const bindUpload = setInterval(() => {
          if (vehicleDocumentUpload.value) {
            vehicleDocumentUpload.value.addEventListener("change", async () => {
              const uploads = [];
              Array.from(vehicleDocumentUpload.value.files).forEach((file) => {
                uploads.push(
                  store
                    .dispatch("saveVehicleDocument", {
                      accountId: company.value.id,
                      vehicleId: vehicle.value.id,
                      file,
                    })
                    .then((fileDoc) => {
                      store.dispatch("addVehicleChangelog", {
                        accountId: company.value.id,
                        vehicleId: vehicle.value.id,
                        entityId: fileDoc.metadata.fullPath,
                        eventType: "vehicle-document-upload",
                        fieldName: "name",
                        oldValue: file.name,
                        newValue: file.name,
                      });
                    }),
                );
              });
              await Promise.all(uploads);
              refreshDocuments();
              vehicleDocumentUpload.value.value = null;
            });
            clearInterval(bindUpload);
            resolve();
          }
        }, 100);
      });
    });

    const original = ref({ ...vehicle.value });

    onMounted(async () => {
      if (!isNew.value) {
        vehicle.value = await store.dispatch("getVehicle", {
          companyId: route.params.companyId,
          vehicleId: route.params.vehicleId,
        });
        changelog.value = await store.dispatch("getVehicleChangelog", {
          companyId: company.value.id,
          vehicleId: vehicle.value.id,
        });
        statusHistory.value = await store.dispatch(
          "getVehicleSubscriptionStatusHistory",
          {
            companyId: company.value.id,
            vehicleId: vehicle.value.id,
            vehicleEntityId: vehicle.value.entityId,
          },
        );
        original.value = { ...vehicle.value };
      }
    });

    async function tryToStartOffRentInitialization() {
      if (!offRent.value.vin || !company.value.type) {
        return;
      }

      await initializeOffRentInfo();
    }

    watch(
      () => vehicle.value.vin,
      async (vin) => {
        if (!vin) {
          return;
        }

        offRent.value.vin = vin;
        updateOffRentValidatableFields();

        await tryToStartOffRentInitialization();
      },
    );

    watch(
      () => company.value.type,
      async (companyType) => {
        if (!companyType) {
          return;
        }

        await tryToStartOffRentInitialization();
      },
    );

    watch(vehicle, () => {
      vehicle.value.companyName = company.value.businessLegalName;
      vehicle.value.created = vehicle.value.created
        ? moment(vehicle.value.created).format("yyyy-MM-DD")
        : "";
      vehicle.value.modified = vehicle.value.modified
        ? moment(vehicle.value.modified).format("yyyy-MM-DD")
        : "";
      vehicle.value.financeCompanyApplicable =
        typeof vehicle.value.financeCompanyApplicable === "boolean"
          ? vehicle.value.financeCompanyApplicable
          : true;
      thumbnailSrc.value = vehicle.value.thumbnail;
    });

    watch(
      () => [
        vehicle.value.registrantFirstName,
        vehicle.value.registrantMiddleName,
        vehicle.value.registrantLastName,
      ],
      () => {
        if (
          vehicle.value.registrantType === "Person" &&
          vehicle.value.registrantFirstName &&
          vehicle.value.registrantLastName
        ) {
          vehicle.value.registrationName = vehicle.value.registrantMiddleName
            ? `${vehicle.value.registrantFirstName} ${vehicle.value.registrantMiddleName} ${vehicle.value.registrantLastName}`
            : `${vehicle.value.registrantFirstName} ${vehicle.value.registrantLastName}`;
        }
      },
    );

    watch(vehicle, (newVehicle, oldVehicle) => {
      if (newVehicle.mileage !== undefined) {
        if (
          isNaN(newVehicle.mileage) ||
          typeof newVehicle.mileage !== "number"
        ) {
          vehicle.value.mileage = oldVehicle.mileage;
        }
      } else {
        vehicle.value.mileage = oldVehicle.mileage;
      }
    });

    watch(statusHistory, () => {
      statusHistory.value.forEach(async (status) => {
        const policy = policies.value.find((p) => p.entityId === status.policy);
        const policyName = policy ? policy.policyNumber : "";
        if (policy) {
          const inputs = {
            stateCode: status.vehicleRegistrationState,
            policyNumber: policyName,
            asOfDate: status.start,
            coverageTerm: "Continuous",
          };
          // if the account is ORP, change the coverageTerm to ORP
          if (policy.coverageUse === "off-rent") {
            inputs.coverageTerm = "Off Rent";
          }
          status.policyID = await store.dispatch("getPolicyNumber", inputs);
        }
      });
    });
    const claimsGridRef = ref(null);
    const claimsGridLoaded = ref(false);
    onMounted(async () => {
      function getClaimsGridMode(type) {
        switch (type) {
          case process.env["VUE_APP_ACCOUNT_TYPE_TRUCKING"]: {
            return "admin_trucks";
          }
          case process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"]:
          default: {
            return "admin_cars";
          }
        }
      }
      if (!claimsGridRef.value) {
        return;
      }
      vehicle.value = await store.dispatch("getVehicle", {
        companyId: route.params.companyId,
        vehicleId: route.params.vehicleId,
      });
      const [claimsGridShadow, claimsGridRoot] = initializeShadowDom(
        claimsGridRef.value,
      );
      await fetchFrontend(
        claimsGridShadow,
        process.env["VUE_APP_CLAIMS_UI_URL"],
      );
      mountFrontend("claims-grid", claimsGridRoot, {
        environment: process.env["VUE_APP_ENVIRONMENT"],
        userId: store.state.user?.sub,
        userEmail: store.state.user?.email,
        graphUrl: process.env["VUE_APP_CLAIMS_GRAPH"],
        graphHeaders: {
          Authorization: `Bearer ${await store.dispatch("getIdentityToken")}`,
        },
        mode: getClaimsGridMode(store.state.type),
        companies: store.state.company,
        vehicles: await store.dispatch(
          "getVehiclesForCompany",
          company.value.id,
        ),
        tenantId: company.value.entityId,
        vehicleId: vehicle.value.entityId,
        onMounted: () => (claimsGridLoaded.value = true),
        selectClaim(evt, claim) {
          if (evt.metaKey) {
            const urlResolve = router.resolve({
              name: "Claim",
              params: {
                tenant: claim.tenant,
                id: claim.id,
              },
            });
            open(urlResolve.fullPath, "_blank");
          } else {
            router.push({
              name: "Claim",
              params: {
                tenant: claim.tenant,
                id: claim.id,
              },
            });
          }
        },
      });
    });

    return {
      isNew,
      original,
      vehicle,
      company,
      isPerson,
      isBusiness,
      carAccountType,
      truckingAccountType,
      thumbnailFileInput,
      thumbnailSrc,
      clickThumbnail() {
        if (thumbnailFileInput.value) {
          thumbnailFileInput.value.click();
        }
      },
      v$,
      timezones,
      offRent,
      isOffRentInputValid,
      getMilesMessage(offRentInfo, targetValue) {
        if (!offRentInfo.isInitialized || offRentInfo.isLoading) {
          return "Loading...";
        } else if (isOffRentInputTouched.value) {
          return "Please Recalculate";
        } else if (!offRentInfo.isRegistered) {
          return "N/A";
        } else {
          return targetValue;
        }
      },
      calculateOffRentMileage() {
        recalculateOffRentInfo(offRentValidatableFields);
      },
      claimsGridRef,
      claimsGridLoaded,
      changelogRefresh,
      async refreshChangelog() {
        changelogRefresh.value = true;
        try {
          const logs = await store.dispatch("getVehicleChangelog", {
            companyId: company.value.id,
            vehicleId: vehicle.value.id,
          });
          changelog.value = [];
          setTimeout(() => {
            changelog.value = logs;
            changelogRefresh.value = false;
          }, 1000);
        } catch (err) {
          toast("Failed to get changelogs.");
          setTimeout(() => (changelogRefresh.value = false), 1000);
        }
      },
      editStatusHistory,
      statusHistory: computed(() => {
        return statusHistory.value
          .map((history) => {
            return {
              ...history,
              start: moment.utc(history.start).format("yyyy-MM-DD"),
              end:
                history.end && moment(history.end).isValid()
                  ? moment.utc(history.end).format("yyyy-MM-DD")
                  : null,
            };
          })
          .filter((h) => (h.archived ? h.archived === false : true))
          .sort(
            (a, b) =>
              moment(b.start, "yyyy-MM-DD").toDate() -
              moment(a.start, "yyyy-MM-DD").toDate(),
          );
      }),
      documents: computed(() => {
        return documents.value
          .map((doc) => {
            return {
              ...doc,
              updated: moment(doc.timeCreated).local().format("yyyy-MM-DD"),
            };
          })
          .filter((doc) =>
            doc.customMetadata ? doc.customMetadata?.archive !== "true" : true,
          );
      }),
      vehicleDocumentUpload,
      editComments,
      uploadVehicleDocument() {
        vehicleDocumentUpload.value?.click();
      },
      async editVehicleDocument(document, action, value = null) {
        switch (action) {
          case "activate": {
            await store.dispatch("saveFileMeta", {
              filePath: document.fullPath,
              meta: {
                customMetadata: {
                  active: true,
                },
              },
            });
            store.dispatch("addVehicleChangelog", {
              accountId: company.value.id,
              vehicleId: vehicle.value.id,
              entityId: document.fullPath,
              eventType: "vehicle-document-meta",
              fieldName: "active",
              oldValue: null,
              newValue: true,
            });
            break;
          }
          case "deactivate": {
            await store.dispatch("saveFileMeta", {
              filePath: document.fullPath,
              meta: {
                customMetadata: {
                  active: null,
                },
              },
            });
            store.dispatch("addVehicleChangelog", {
              accountId: company.value.id,
              vehicleId: vehicle.value.id,
              entityId: document.fullPath,
              eventType: "vehicle-document-meta",
              fieldName: "active",
              oldValue: true,
              newValue: null,
            });
            break;
          }
          case "archive": {
            if (
              confirm("Are you sure you want to archive this vehicle document?")
            ) {
              await store.dispatch("saveFileMeta", {
                filePath: document.fullPath,
                meta: {
                  customMetadata: {
                    active: null,
                    archive: true,
                  },
                },
              });
              store.dispatch("addVehicleChangelog", {
                accountId: company.value.id,
                vehicleId: vehicle.value.id,
                entityId: document.fullPath,
                eventType: "vehicle-document-meta",
                fieldName: "archive",
                oldValue: null,
                newValue: true,
              });
            }
            break;
          }
          case "tag": {
            const tag = value.target.value;
            await store.dispatch("saveFileMeta", {
              filePath: document.fullPath,
              meta: { customMetadata: { tag } },
            });
            store.dispatch("addVehicleChangelog", {
              accountId: company.value.id,
              vehicleId: vehicle.value.id,
              entityId: document.fullPath,
              eventType: "vehicle-document-meta",
              fieldName: "tag",
              oldValue: document.customMetadata?.tag || null,
              newValue: tag,
            });
            break;
          }
        }
        refreshDocuments();
      },
      async selectVehicleDocument(document) {
        store.dispatch("logEvent", { name: "download_vehicle_document" });
        const url = await store.dispatch(
          "getFileDownloadUrl",
          document.fullPath,
        );
        window.open(url, "_blank");
      },
      async saveComments() {
        vehicle.value = await store.dispatch("saveVehicleComments", {
          vehicleId: vehicle.value.id,
          companyId: company.value.id,
          comments: vehicle.value.comments || "",
        });
        toast("Comments saved.");
        editComments.value = false;
      },
      changelog: computed(() => {
        return changelog.value.map((change) => {
          return {
            id: change.id,
            change: getChangelogDescription(change),
            email: change.actor,
            created: moment(change.timestamp)
              .local()
              .format("yyyy-MM-DD h:mmA"),
          };
        });
      }),
      vehicleTab,
      details: computed(() => {
        const exFields = getExcludedFieldsForAccountType(company.value.type);
        return details
          .filter(({ tab }) => tab === vehicleTab.value)
          .filter(({ key }) => !exFields.includes(key))
          .map((detail) => {
            if (detail.key === "registrationName") {
              const name =
                vehicle.value.registrantType === "Business"
                  ? "Registrant Company Name"
                  : "Registration Name";
              detail.name = name;
            }
            return detail;
          });
      }),
      getDetailsForSection(details, tabname, section) {
        return details.filter((detail) => {
          const enabled = this.getLaunchDarklyFlag(detail.launchDarkly);
          return (
            detail.tab === tabname && detail.section === section && enabled
          );
        });
      },
      getSectionsForTab(details, tabname) {
        const sections = [];
        details
          .filter(({ tab }) => tab === tabname)
          .forEach((detail) => {
            if (detail.section && !sections.includes(detail.section)) {
              sections.push(detail.section);
            }
          });
        return sections;
      },
      changelogColumns: [
        { name: "Change", id: "change" },
        { name: "Created By", id: "email" },
        { name: "Created", id: "created" },
      ],
      insuranceCriteriaChange() {
        if (vehicle.value.insuranceCriteriaStatus === "Declined") {
          vehicle.value.status = "Inactive";
        }
        if (vehicle.value.insuranceCriteriaStatus === "Under Review") {
          vehicle.value.status = "Requested";
        }
        if (vehicle.value.insuranceCriteriaStatus === "Approved") {
          vehicle.value.status = "Inactive";
        }
      },
      async saveVehicle() {
        if (!(vehicle.value.vin || "").trim()) {
          alert("VIN missing from vehicle.");
          return;
        }
        if (isNew.value) {
          const vehicles = await store.dispatch(
            "getVehiclesForCompany",
            company.value.id,
          );
          if (vehicles.find(({ vin }) => vehicle.value.vin === vin)) {
            alert("VIN already added to this company.");
            return;
          }
        }
        if (
          company.value.type === process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"]
        ) {
          if (vehicle.value.insuranceCriteriaStatus === "Approved") {
            // Registrant type checks.
            if (!vehicle.value.registrantType) {
              alert("Warning: A registrant type is suggested.");
            }
            if (
              !vehicle.value.registrationName &&
              vehicle.value.registrantType !== "Person"
            ) {
              alert("Warning: A registration name is suggested.");
            }
            if (
              (!vehicle.value.registrantFirstName ||
                !vehicle.value.registrantLastName) &&
              vehicle.value.registrantType === "Person"
            ) {
              alert("Warning: A first and last name is suggested.");
            }
            // Registration address checks
            if (
              !vehicle.value.registrationAddressLineOne ||
              !vehicle.value.registrationCity ||
              !vehicle.value.registrationState ||
              !vehicle.value.registrationZipcode
            ) {
              alert(
                "Must set vehicle registration address before activating a subscription.",
              );
              return;
            }

            if (!vehicle.value.registrationState) {
              alert("A registration state is required.");
              return;
            }
            // DL# checks
            if (
              vehicle.value.registrantGovernmentIdType &&
              vehicle.value.registrantGovernmentIdType === "DriverLicense"
            ) {
              const state = vehicle.value.driverLicenseStateIssued;
              const dln = vehicle.value.registrantGovernmentId || "";

              if (state) {
                const validOrDesc = isValidOrReturnDescription(state, dln);
                if (Array.isArray(validOrDesc)) {
                  alert(
                    `Driver license number expected format: ${validOrDesc}`,
                  );
                  return;
                }
              }
            }
            // Finance company checks
            if (vehicle.value.financeCompanyApplicable) {
              if (!vehicle.value.financeCompanyName) {
                alert("Finance company name is required.");
                return;
              }
              if (
                !vehicle.value.financeCompanyAddressLineOne ||
                !vehicle.value.financeCompanyCity ||
                !vehicle.value.financeCompanyState ||
                !vehicle.value.financeCompanyZipcode
              ) {
                alert("Finance company address is required.");
                return;
              }
            }
            // Lot checks
            if (
              !vehicle.value.lotAddressLineOne ||
              !vehicle.value.lotCity ||
              !vehicle.value.lotState ||
              !vehicle.value.lotZipcode
            ) {
              alert("Lot address is required.");
              return;
            }
          }
        }
        if ("mileage" in vehicle.value && vehicle.value.mileage) {
          if (isNaN(vehicle.value.mileage)) {
            alert("Warning: Mileage should be a number.");
            return;
          } else {
            try {
              vehicle.value.mileage = parseInt(vehicle.value.mileage);
            } catch (err) {
              alert("Warning: Mileage should be a number.");
              return;
            }
          }
        }

        try {
          const vehicles = await store.dispatch(
            "getVehiclesForCompany",
            company.value.id,
          );
          if (
            vehicles.filter((v) => v.status === "Active").length >=
            company.value.spots
          ) {
            if (
              original.value.status !== "Active" &&
              vehicle.value.status === "Active"
            ) {
              toast("Attempting to prorate vehicle...");
            }
          }
          const savedVehicle = await store.dispatch("saveVehicle", {
            ...vehicle.value,
            companyId: company.value.id,
          });
          toast("Vehicle saved.");
          vehicle.value = savedVehicle;
          router.replace({
            name: "Vehicle",
            params: { companyId: company.value.id, vehicleId: savedVehicle.id },
          });
          original.value = { ...vehicle.value };
        } catch (err) {
          switch (err.message) {
            case "Failed to prorate vehicle": {
              toast("Failed to prorate vehicle.");
              break;
            }
            default: {
              toast("Failed to save vehicle.");
            }
          }
        }
      },
      async vinLookup() {
        try {
          const lookup = await axios({
            method: "GET",
            url: `https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVin/${vehicle.value.vin}?format=JSON`,
          });

          const results = lookup.data["Results"];

          const make = results.find((x) => x["Variable"] === "Make");
          const model = results.find((x) => x["Variable"] === "Model");
          const year = results.find((x) => x["Variable"] === "Model Year");
          const weight = results.find(
            (x) => x["Variable"] === "Gross Vehicle Weight Rating From",
          );

          if (make && make["Value"]) {
            vehicle.value.make = make["Value"];
          }

          if (model && model["Value"]) {
            vehicle.value.model = model["Value"];
          }

          if (year && year["Value"]) {
            vehicle.value.year = year["Value"];
          }

          if (weight && weight["Value"]) {
            vehicle.value.class = weight["Value"];
          }

          toast("VIN Lookup Complete");
        } catch (err) {
          toast("Failed to get VIN information.");
        }
      },
      policies,
      getPolicyName(entityId) {
        const policy = policies.value.find((p) => p.entityId === entityId);
        return policy ? policy.name : "";
      },
      selectPolicy(entityId) {
        const policy = policies.value.find((p) => p.entityId === entityId);
        if (policy) {
          router.push({
            name: "Policy",
            params: { policyId: policy.id },
          });
        }
      },
      getEffectiveDate(effDate) {
        if (effDate && moment(effDate).isValid()) {
          return moment(effDate, "yyyy-MM-DD").tz("America/New_York");
        }
        return null;
      },
      newSubscriptionStatus,
      async saveSubscriptionStatus(status) {
        if (
          company.value.type === process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"]
        ) {
          if (!status.policy) {
            alert("No policy selected.");
            return;
          }
        }

        if (!status.start) {
          alert("Effective start date not set.");
          return;
        }

        let momentStart = this.getEffectiveDate(status.start);
        let momentEnd = this.getEffectiveDate(status.end);
        if (momentEnd) {
          if (momentEnd.toDate() < momentStart.toDate()) {
            alert("Effective end date can not be before effective start date.");
            return;
          }
          momentEnd = momentEnd.startOf("day").add(1, "minutes").format();
        }
        momentStart = momentStart.startOf("day").add(1, "minutes").format();
        const originalStatus = status.id
          ? statusHistory.value.find(({ id }) => id === status.id)
          : null;
        const savedStatus = await store.dispatch(
          "saveVehicleSubscriptionStatus",
          {
            accountId: company.value.id,
            vehicleId: vehicle.value.id,
            status: {
              ...status,
              status: "Active",
              start: momentStart,
              end: momentEnd,
            },
          },
        );

        if (!savedStatus) {
          toast(
            `Subscription status failed to save. Check the selected values and try again`,
          );
          return;
        }

        // Base values for the changelog messages.
        const baseChangelog = {
          accountId: company.value.id,
          vehicleId: vehicle.value.id,
          entityId: savedStatus.id,
          eventType: "vehicle-subscription-status",
        };

        if (
          !originalStatus ||
          moment.utc(originalStatus.start).format("yyyy-MM-DD") !==
            moment.utc(savedStatus.start).format("yyyy-MM-DD")
        ) {
          await store.dispatch("addVehicleChangelog", {
            ...baseChangelog,
            fieldName: "start",
            oldValue: originalStatus
              ? moment.utc(originalStatus.start).format()
              : null,
            newValue: moment.utc(savedStatus.start).format(),
          });
        }
        if (
          !originalStatus ||
          moment.utc(originalStatus.end).format("yyyy-MM-DD") !==
            moment.utc(savedStatus.end).format("yyyy-MM-DD")
        ) {
          await store.dispatch("addVehicleChangelog", {
            ...baseChangelog,
            fieldName: "end",
            oldValue:
              originalStatus && originalStatus.end
                ? moment.utc(originalStatus.end).format()
                : null,
            newValue: savedStatus.end
              ? moment.utc(savedStatus.end).format()
              : null,
          });
        }
        if (
          !originalStatus ||
          originalStatus.vehicleRegistrationState !=
            savedStatus.vehicleRegistrationState
        ) {
          await store.dispatch("addVehicleChangelog", {
            ...baseChangelog,
            fieldName: "vehicleRegistrationState",
            oldValue:
              originalStatus && originalStatus.vehicleRegistrationState
                ? originalStatus.vehicleRegistrationState
                : null,
            newValue: savedStatus.vehicleRegistrationState,
          });
        }
        toast(`Subscription status ${status.id ? "saved" : "added"}.`);
        if (status.id) {
          editStatusHistory.value = {
            enabled: true,
            id: null,
            start: null,
            end: null,
          };
        } else {
          newSubscriptionStatus.value = { start: null, end: null };
        }
        statusHistory.value = await store.dispatch(
          "getVehicleSubscriptionStatusHistory",
          {
            companyId: company.value.id,
            vehicleId: vehicle.value.id,
            vehicleEntityId: vehicle.value.entityId,
          },
        );
      },
      async removeSubscriptionStatus(history) {
        if (confirm("Remove subscription status from vehicle history?")) {
          await store.dispatch("saveVehicleSubscriptionStatus", {
            accountId: company.value.id,
            vehicleId: vehicle.value.id,
            status: { ...history, archived: true },
          });
          await store.dispatch("addVehicleChangelog", {
            accountId: company.value.id,
            vehicleId: vehicle.value.id,
            entityId: history.id,
            eventType: "vehicle-subscription-status",
            fieldName: "archive",
            oldValue: null,
            newValue: true,
          });
          statusHistory.value = await store.dispatch(
            "getVehicleSubscriptionStatusHistory",
            {
              companyId: company.value.id,
              vehicleId: vehicle.value.id,
              vehicleEntityId: vehicle.value.entityId,
            },
          );
        }
      },
      copyCustomerLink() {
        const hostname = window.location.origin.replace("paddocks", "app");
        let vehiclePath = "vehicle";
        if (
          company.value.type === process.env["VUE_APP_ACCOUNT_TYPE_TRUCKING"]
        ) {
          vehiclePath = "fleet";
        }
        navigator.clipboard.writeText(
          `${hostname}/${vehiclePath}/${vehicle.value.entityId}`,
        );
        toast("Customer vehicle link copied.");
      },
      viewSafetyLogs() {
        const ktLink = `https://web.keeptruckin.com/en-US/#/safety/events;vehicle_ids=${vehicle.value.keeptruckinId};only_with_videos=false;all_drive_events=false`;
        window.open(ktLink, "_blank");
      },
      async generateCertificate() {
        toast("Requesting certificates.");
        if (company.value.abiIntegration) {
          const certResponse = await store.dispatch("generateCertificate", {
            account: { ...company.value, documentId: company.value.id },
            vehicle: { ...vehicle.value, documentId: vehicle.value.id },
          });
          if (certResponse.success) {
            toast("Certificates are being created.");
          } else {
            toast("Failed to create certificates.");
          }
          return;
        }
        try {
          let success = false;
          const companyCertResponse = await store.dispatch(
            "generateCertificate",
            {
              ...vehicle.value,
              certificateHolder: company.value.businessLegalName,
              addressLineOne: company.value.businessAddressLineOne,
              addressLineTwo: company.value.businessAddressLineTwo || "",
              zipcode: company.value.businessZipcode,
              city: company.value.businessCity,
              state: company.value.state,
              certificateState: vehicle.value.registrationState,
              certificateType: "CMP",
              accountId: company.value.id,
            },
          );
          success = companyCertResponse.success;
          if (
            success &&
            company.value.businessLegalName !== vehicle.value.registrationName
          ) {
            const registrationCertResponse = await store.dispatch(
              "generateCertificate",
              {
                ...vehicle.value,
                certificateHolder: vehicle.value.registrationName,
                addressLineOne: vehicle.value.registrationAddressLineOne,
                addressLineTwo: vehicle.value.registrationAddressLineTwo || "",
                zipcode: vehicle.value.registrationZipcode,
                city: vehicle.value.registrationCity,
                state: vehicle.value.registrationState,
                certificateState: vehicle.value.registrationState,
                certificateType: "REG",
                accountId: company.value.id,
              },
            );
            success = registrationCertResponse.success;
          }
          if (success && vehicle.value.financeCompanyApplicable) {
            const financeCertResponse = await store.dispatch(
              "generateCertificate",
              {
                ...vehicle.value,
                certificateHolder: vehicle.value.financeCompanyName,
                addressLineOne: vehicle.value.financeCompanyAddressLineOne,
                addressLineTwo:
                  vehicle.value.financeCompanyAddressLineTwo || "",
                zipcode: vehicle.value.financeCompanyZipcode,
                city: vehicle.value.financeCompanyCity,
                state: vehicle.value.financeCompanyState,
                certificateState: vehicle.value.registrationState,
                certificateType: "FIN",
                accountId: company.value.id,
              },
            );
            success = financeCertResponse.success;
          }
          if (success) {
            toast(`Certificate is being created.`);
          } else {
            toast("Failed to create certificate.");
          }
        } catch (err) {
          toast("Failed to create certificate.");
        }
      },
      addCompanyInformation(section) {
        if (section === "Registration Details") {
          vehicle.value.registrationName = company.value.businessLegalName;
          vehicle.value.registrationAddressLineOne =
            company.value.businessAddressLineOne;
          vehicle.value.registrationAddressLineTwo =
            company.value.businessAddressLineTwo;
          vehicle.value.registrationCity = company.value.businessCity;
          vehicle.value.registrationZipcode = company.value.businessZipcode;
          vehicle.value.registrationState = company.value.state;

          if (vehicle.value.registrantGovernmentIdType === "FEIN") {
            vehicle.value.registrantGovernmentId = company.value.einNumber;
          }
          toast("Company information added.");
        }
        if (section === "Finance Company") {
          vehicle.value.financeCompanyName = company.value.businessLegalName;
          vehicle.value.financeCompanyAddressLineOne =
            company.value.businessAddressLineOne;
          vehicle.value.financeCompanyAddressLineTwo =
            company.value.businessAddressLineTwo;
          vehicle.value.financeCompanyCity = company.value.businessCity;
          vehicle.value.financeCompanyZipcode = company.value.businessZipcode;
          vehicle.value.financeCompanyState = company.value.state;
          toast("Company information added.");
        }
        if (section === "Lot Information") {
          vehicle.value.lotAddressLineOne =
            company.value.businessAddressLineOne;
          vehicle.value.lotAddressLineTwo =
            company.value.businessAddressLineTwo;
          vehicle.value.lotCity = company.value.businessCity;
          vehicle.value.lotZipcode = company.value.businessZipcode;
          vehicle.value.lotState = company.value.state;
          toast("Company information added.");
        }
      },

      async generateVehicleDocuments() {
        toast("Generating vehicle documents.");
        await generateDoc("CoiCustomer");
        await generateDoc("CoiRegistrant");
        await generateDoc("InsuranceCard");
        if (vehicle.value.financeCompanyApplicable)
          await generateDoc("CoiLienHolder");
        refreshDocuments();
      },
      vehicleRegistrationStates: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
      ],
      formatRegistrantGovernmentId: () => {
        if (vehicle.value.registrantGovernmentIdType === "FEIN") {
          vehicle.value.registrantGovernmentId = formatEin(
            vehicle.value.registrantGovernmentId,
          );
        }
      },
      onChangeRegistrantType: (evt) => {
        switch (evt.target.value) {
          case "Business":
            vehicle.value.registrantFirstName = "";
            vehicle.value.registrantMiddleName = "";
            vehicle.value.registrantLastName = "";
            return;
          case "Person":
            if (vehicle.value.registrantFirstName == undefined) {
              vehicle.value.registrantFirstName = "";
              vehicle.value.registrantMiddleName = "";
              vehicle.value.registrantLastName = "";
            }
            return;
          default:
            return;
        }
      },
      mileageRangeOptions: computed(() => {
        return [
          { value: "<25000", label: "Less than 25,000mi" },
          { value: "25001-50000", label: "25,001mi - 50,000mi" },
          { value: "50001-75000", label: "50,001mi - 75,000mi" },
          { value: "75001-100000", label: "75,001mi - 100,000mi" },
          { value: "100001-125000", label: "100,001mi - 125,000mi" },
          { value: "125001-150000", label: "125,001mi - 150,000mi" },
          { value: "150001-175000", label: "150,001mi - 175,000mi" },
          { value: "175001-200000", label: "175,001mi - 200,000mi" },
          { value: "200001-225000", label: "200,001mi - 225,000mi" },
          { value: "225001-250000", label: "225,001mi - 250,000mi" },
          { value: ">250000", label: "Greater than 250,000mi" },
        ];
      }),
      onChangeVehicleRegistrationState: (evt) => {
        if (evt.target.value !== "NY") {
          vehicle.value.registrationClass = undefined;
        }
      },
      getLaunchDarklyFlag: (flag) => {
        if (!detailsLaunchDarklyFlags.value || flag === undefined) {
          return true;
        }
        const f = detailsLaunchDarklyFlags.value.find((d) => d.flag === flag);
        if (f) {
          return f.enabled;
        }

        return true;
      },
      goToVehicleDetail: () => {
        const url = `${process.env["VUE_APP_UNDERWRITING_TRAIL_PAGE"]}?vin=${vehicle.value.vin}`;
        window.open(url, "_blank");
      },
    };
  },
};
</script>
