<template>
  <div class="p-4 md:p-8" v-if="company.id">
    <div class="w-full bg-white rounded-2xl p-5">
      <div class="w-full flex flex-row justify-between items-end">
        <h1 class="font-bold text-base text-2xl text-gray-800">
          {{ company.businessLegalName }}
          {{ company.tenantId ? `(${company.tenantId})` : "" }}
        </h1>
        <p class="hidden text-gray-600 font-bold mt-1 mx-1 text-2xl lg:block">
          {{ company.contactFirstName }} {{ company.contactLastName }}
        </p>
      </div>
      <div class="w-full flex flex-row justify-between items-end">
        <h1 class="font-bold text-base text-2xl text-gray-800">
          Product:
          {{ company.coverageType === "LulaSafe" ? "LulaSafe Only" : "" }}
          {{ company.coverageType === "GAIL" ? "GAIL Prospect" : "" }}
          {{
            traditionalPolicies.findIndex((policy) =>
              (company.defaultPolicy || "").includes(policy.entityId),
            ) >= 0
              ? "Continuous Coverage"
              : ""
          }}
          {{
            offRentPolicies.findIndex((policy) =>
              (company.defaultPolicy || "").includes(policy.entityId),
            ) >= 0
              ? "ORP"
              : ""
          }}
          {{
            company.administratorId === "16bc9b76-06c8-4c1e-a6e2-5356105c72c7"
              ? "GBA"
              : ""
          }}
        </h1>
      </div>
      <hr class="mt-3" />
      <div class="grid sm:grid-cols-3">
        <button
          :class="{ 'bg-gray-100': accountTab === 'account' }"
          class="rounded-none text-center py-4 md:p-4 select-none cursor-pointer hover:bg-gray-100 font-bold md:text-xl"
          @click="accountTab = 'account'"
        >
          Account Details
        </button>
        <button
          :class="{ 'bg-gray-100': accountTab === 'contact' }"
          class="rounded-none text-center py-4 md:p-4 select-none cursor-pointer hover:bg-gray-100 font-bold md:text-xl"
          @click="accountTab = 'contact'"
        >
          Contact Info
        </button>
        <button
          :class="{ 'bg-gray-100': accountTab === 'price' }"
          class="rounded-none text-center py-4 md:p-4 select-none cursor-pointer hover:bg-gray-100 font-bold md:text-xl"
          @click="accountTab = 'price'"
        >
          Products
        </button>
      </div>
      <form @submit.prevent="saveCompany" v-if="company">
        <div class="w-full text-md grid sm:grid-cols-2 md:text-lg">
          <div
            class="m-2"
            v-if="
              accountTab === 'price' &&
              (stripeCustomerLink || !company.stripeId)
            "
          >
            <div>
              <label class="font-bold">Stripe Customer</label>
              <br />
              <input
                type="text"
                v-model="company.stripeId"
                v-if="editStripeId"
              />
              <a
                :href="stripeCustomerLink"
                target="_blank"
                className="block bg-gray-100 w-full py-1 px-2 rounded-lg cursor-pointer hover:bg-gray-200"
                v-else
              >
                {{ company.stripeId }}
              </a>
            </div>
            <div class="flex justify-between px-2" v-if="!editStripeId">
              <span
                class="cursor-pointer font-bold cursor-pointer text-sm hover:underline"
                @click="manualInvoice(false)"
                v-if="company.status === 'Active'"
              >
                Manual invoice
              </span>
              <span v-else />
              <span
                class="cursor-pointer font-bold cursor-pointer text-sm hover:underline"
                @click="editStripeId = true"
              >
                Edit stripe id
              </span>
            </div>
          </div>
          <div
            class="m-2"
            v-if="accountTab === 'price' && company.status === 'Active'"
          >
            <div>
              <label class="font-bold">Stripe Test Customer</label>
              <br />
              <a :href="stripeTestCustomerLink" target="_blank">
                <input
                  class="cursor-pointer"
                  type="text"
                  :value="company.stripeTestId"
                  disabled
                />
              </a>
            </div>
            <div class="flex justify-bewteen px-2">
              <span
                class="cursor-pointer font-bold cursor-pointer text-sm hover:underline"
                @click="manualInvoice(true)"
                v-if="company.status === 'Active'"
              >
                Manual invoice
              </span>
            </div>
          </div>
          <div class="m-2" v-if="accountTab === 'price'">
            <label class="font-bold">Service Agreement Spots</label>
            <br />
            <input
              type="number"
              min="0"
              v-model="company.serviceAgreementSpots"
              :disabled="
                company.status !== 'Onboarding' && company.status !== 'Hold'
              "
            />
          </div>
          <div class="m-2" v-if="accountTab === 'account' && salesforceLink">
            <label class="font-bold">Salesforce Account</label>
            <br />
            <a
              :href="salesforceLink"
              target="_blank"
              className="block bg-gray-100 w-full py-1 px-2 rounded-lg cursor-pointer hover:bg-gray-200"
            >
              {{ company.salesforceId }}
            </a>
          </div>
          <div class="m-2" v-for="detail in details" :key="detail.key">
            <label class="font-bold">{{ detail.name }}</label>
            <br />
            <state-select
              v-if="detail.key === 'state'"
              v-model="company[detail.key]"
            />
            <select
              v-else-if="detail.key === 'demo'"
              v-model="company[detail.key]"
            >
              <option :value="false">Not Demo Account</option>
              <option :value="true">Demo Account</option>
            </select>
            <select
              v-else-if="detail.key === 'authorityType'"
              v-model="company[detail.key]"
            >
              <option value="intrastate">Intrastate</option>
              <option value="interstate">Interstate</option>
            </select>
            <input
              v-else-if="detail.key === 'einNumber'"
              v-model="company[detail.key]"
              @blur="formatEIN"
              title="Please enter a value in the format XX-XXXXXXX"
            />
            <input
              v-else-if="detail.key === 'serviceStart'"
              :type="detail.type"
              :disabled="detail.disabled === true"
              v-model="company[detail.key]"
              :min="minServiceStartDate"
              :max="maxServiceStartDate"
            />
            <input
              v-else
              :type="detail.type"
              v-model="company[detail.key]"
              :disabled="detail.disabled === true"
            />
            <div
              class="flex justify-between"
              v-if="detail.key === 'dotNumber' && company.dotNumber"
            >
              <span />
              <a
                :href="`https://ai.fmcsa.dot.gov/SMS/Carrier/${company.dotNumber}/CompleteProfile.aspx`"
                target="_blank"
                class="font-bold cursor-pointer hover:underline text-sm"
              >
                View FMCSA
              </a>
            </div>
          </div>

          <div class="flex items-center m-2">
            <div v-if="isCreditWalletComponentLoading" class="w-full">
              Loading component...
            </div>
            <div ref="creditWalletContainerRef" class="w-full"></div>
          </div>

          <div
            class="p-4 rounded-xl bg-gray-100 my-4 col-span-full"
            v-if="accountTab === 'account'"
          >
            <h2 class="text-xl font-bold text-gray-800 mb-2">
              Business Address
            </h2>
            <hr />
            <div class="grid grid-cols-1 mt-2 sm:grid-cols-3">
              <div
                v-for="detail in businessAddressDetails"
                :key="detail.key"
                :class="{
                  'sm:col-span-2': detail.key === 'businessAddressLineOne',
                }"
                class="mx-1 mb-1 sm:mb-0"
              >
                <label class="font-bold text-gray-800">
                  {{ detail.name }}
                </label>
                <br />
                <state-select
                  class="bg-white"
                  v-model="company[detail.key]"
                  v-if="detail.key === 'state'"
                />
                <input
                  class="bg-white"
                  :type="detail.type"
                  v-model="company[detail.key]"
                  v-else
                />
              </div>
            </div>
          </div>
          <div
            class="p-4 rounded-xl bg-gray-100 my-4 col-span-full"
            v-if="
              accountTab === 'account' && company.type === truckingAccountType
            "
          >
            <h2 class="text-xl font-bold text-gray-800 mb-2">
              Garaging Address
            </h2>
            <hr />
            <div class="grid grid-cols-1 mt-2 sm:grid-cols-3">
              <div
                v-for="detail in garagingAddressDetails"
                :key="detail.key"
                :class="{
                  'sm:col-span-2': detail.key === 'garagingAddressLineOne',
                }"
                class="mx-1 mb-1 sm:mb-0"
              >
                <label class="font-bold text-gray-800">
                  {{ detail.name }}
                </label>
                <br />
                <state-select
                  class="bg-white"
                  v-model="company[detail.key]"
                  v-if="detail.key === 'garagingState'"
                />
                <input
                  class="bg-white"
                  :type="detail.type"
                  v-model="company[detail.key]"
                  v-else
                />
              </div>
            </div>
          </div>
          <div
            class="p-4 rounded-xl bg-gray-100 my-4 col-span-full"
            v-if="accountTab === 'account'"
          >
            <h2
              class="text-xl font-bold text-gray-800 mb-2"
              v-if="confirmActivation.confirm"
            >
              Confirm Activation
            </h2>
            <h2 class="text-xl font-bold text-gray-800 mb-2" v-else>
              Account Status
            </h2>
            <hr />
            <div class="mt-2" v-if="confirmActivation.confirm">
              <div class="flex flex-col">
                <div class="w-full m-1">
                  <span class="font-bold"> Effective Date </span>
                  <br />
                  <input
                    class="bg-white"
                    type="date"
                    v-model="confirmActivation.effectiveDate"
                  />
                </div>
                <div class="w-full m-1">
                  <span class="font-bold"> Auto Charge </span>
                  <br />
                  <select
                    class="bg-white"
                    v-model="confirmActivation.autoCharge"
                  >
                    <option :value="false">Only send initial invoice</option>
                    <option :value="true">Auto charge initial invoice</option>
                  </select>
                </div>
              </div>
              <div class="flex justify-between text-sm">
                <span
                  @click="confirmActivation.confirm = false"
                  class="font-bold text-gray-800 cursor-pointer hover:underline"
                >
                  Cancel Activation
                </span>
                <span
                  @click="activateAccount"
                  class="font-bold text-gray-800 cursor-pointer hover:underline"
                >
                  Confirm Activation
                </span>
              </div>
            </div>
            <div class="mt-2" v-else>
              <select
                class="bg-white mb-2"
                v-if="currentCompanyStatus"
                v-model="company.status"
                @change="
                  company.statusReason = null;
                  company.statusReasonDetails = null;
                "
              >
                <option
                  v-if="
                    (currentCompanyStatus !== 'Active' &&
                      currentCompanyStatus !== 'Inactive') ||
                    company.status === 'Hold'
                  "
                >
                  Hold
                </option>
                <option v-if="currentCompanyStatus !== 'Active'">
                  Onboarding
                </option>
                <option
                  v-if="
                    currentCompanyStatus !== 'Active' &&
                    company.type === carAccountType
                  "
                >
                  Pending Activation
                </option>
                <option disabled v-if="currentCompanyStatus === 'Active'">
                  Active
                </option>
                <option>Inactive</option>
              </select>

              <select
                class="bg-white mb-2"
                v-model="company.statusReason"
                v-if="currentCompanyStatus && company.status === 'Hold'"
                @change="company.statusReasonDetails = null"
              >
                <option :value="null" disabled>Select a reason</option>
                <option>Waiting on customer</option>
                <option>Waiting on coverage</option>
              </select>
              <select
                class="bg-white mb-2"
                v-model="company.statusReason"
                v-if="currentCompanyStatus && company.status === 'Inactive'"
                @change="company.statusReasonDetails = null"
              >
                <option :value="null" disabled>Select a reason</option>
                <option>Failed to onboard</option>
                <option>Missed payments</option>
                <option>Voluntary churn</option>
                <option>Compliance</option>
                <option>Program Switch</option>
                <option>Duplicate Account</option>
              </select>

              <div
                v-if="
                  currentCompanyStatus &&
                  [
                    'Voluntary churn',
                    'Compliance',
                    'Program Switch',
                    'Duplicate Account',
                  ].includes(company.statusReason)
                "
              >
                <input
                  class="bg-white mb-2"
                  placeholder="Additional Details (Optional)"
                  v-model="company.statusReasonDetails"
                />
              </div>
              <div
                class="flex justify-end"
                v-if="readyToGoActive && getLaunchDarklyFlag('admin.policy')"
              >
                <span />
                <span
                  class="font-bold text-gray-800 text-sm cursor-pointer hover:underline"
                  @click="activateAccount"
                  v-if="company.type === carAccountType"
                >
                  Activate Account
                </span>
                <span
                  class="font-bold text-gray-800 text-sm cursor-pointer hover:underline"
                  @click="recreateInvoice"
                  v-if="company.type === truckingAccountType"
                >
                  Recreate initial invoice
                </span>
              </div>
            </div>
          </div>
          <div
            class="p-4 rounded-xl bg-gray-100 mt-4 col-span-full"
            v-if="company.type === carAccountType && accountTab === 'price'"
          >
            <h2 class="text-xl font-bold text-gray-800 mb-2">Coverages</h2>
            <form
              class="flex flex-col items-center md:flex-row"
              @submit.prevent="submitCompanyPolicy"
              v-if="newPolicy.toggle"
            >
              <div class="w-full m-1">
                <input
                  class="bg-white border md:mr-1"
                  placeholder="Coverage Number"
                  required
                  v-model="newPolicy.policyNumber"
                />
              </div>
              <div class="w-full m-1">
                <input
                  class="bg-white border md:mr-1"
                  placeholder="Coverage Name"
                  required
                  v-model="newPolicy.name"
                />
              </div>
              <div class="w-full m-1 md:w-min">
                <button
                  type="submit"
                  class="w-full rounded-none py-1 bg-white text-gray-600 rounded-lg whitespace-nowrap border hover:text-gray-800 md:w-max"
                >
                  Add Coverage
                </button>
              </div>
            </form>
            <hr class="my-2" />
            <h3 class="text-base font-bold text-gray-800 mb-2">On Lot</h3>
            <div>
              <div v-if="offRentPolicies.length > 0">
                <div
                  class="flex items-start justify-between bg-white rounded p-2 my-1 border cursor-pointer hover:bg-gray-50"
                  :class="{
                    'border-lula': (company.defaultPolicy || '').includes(
                      policy.entityId,
                    ),
                    'border-4': (company.defaultPolicy || '').includes(
                      policy.entityId,
                    ),
                  }"
                  @click="togglePolicy(policy)"
                  v-for="policy in offRentPolicies"
                  :key="policy.entityId"
                >
                  <div class="text-sm mx-2">
                    <span class="font-bold text-gray-600">
                      {{ policy.name }}
                    </span>
                    <br />
                    <span class="font-bold text-gray-500">
                      {{ policy.policyNumber }}
                      <span class="hidden sm:inline">
                        ({{ policy.effectiveStart }} -
                        {{ policy.effectiveEnd }})
                      </span>
                    </span>
                  </div>
                  <span
                    class="bg-lula-alt rounded-full text-white px-4 text-xs font-bold"
                  >
                    {{ (policy.broker || "").toUpperCase() }}
                  </span>
                </div>
              </div>
              <div
                class="bg-white border rounded text-center text-sm p-4"
                v-else
              >
                No Policies
              </div>
            </div>
            <h3 class="text-base font-bold text-gray-800 my-2">On Rent</h3>
            <div>
              <div v-if="onRentPolicies.length > 0">
                <div
                  class="flex items-start justify-between bg-white rounded p-2 my-1 border cursor-pointer hover:bg-gray-50"
                  :class="{
                    'border-lula': (company.defaultPolicy || '').includes(
                      policy.entityId,
                    ),
                    'border-4': (company.defaultPolicy || '').includes(
                      policy.entityId,
                    ),
                  }"
                  @click="togglePolicy(policy)"
                  v-for="policy in onRentPolicies"
                  :key="policy.entityId"
                >
                  <div class="text-sm mx-2">
                    <span class="font-bold text-gray-600">
                      {{ policy.name }}
                    </span>
                    <br />
                    <span class="font-bold text-gray-500">
                      {{ policy.policyNumber }}
                      <span class="hidden sm:inline">
                        ({{ policy.effectiveStart }} -
                        {{ policy.effectiveEnd }})
                      </span>
                    </span>
                  </div>
                  <span
                    class="bg-lula-alt rounded-full text-white px-4 text-xs font-bold"
                  >
                    {{ (policy.broker || "").toUpperCase() }}
                  </span>
                </div>
              </div>
              <div
                class="bg-white border rounded text-center text-sm p-4"
                v-else
              >
                No Policies
              </div>
            </div>
            <h3 class="text-base font-bold text-gray-800 my-2">Traditional</h3>
            <div>
              <div v-if="traditionalPolicies.length > 0">
                <div
                  class="flex items-start justify-between bg-white rounded p-2 my-1 border cursor-pointer hover:bg-gray-50"
                  :class="{
                    'border-lula': (company.defaultPolicy || '').includes(
                      policy.entityId,
                    ),
                    'border-4': (company.defaultPolicy || '').includes(
                      policy.entityId,
                    ),
                  }"
                  @click="togglePolicy(policy)"
                  v-for="policy in traditionalPolicies"
                  :key="policy.entityId"
                >
                  <div class="text-sm mx-2">
                    <span class="font-bold text-gray-600">
                      {{ policy.name }}
                    </span>
                    <br />
                    <span class="font-bold text-gray-500">
                      {{ policy.policyNumber }}
                      <span class="hidden sm:inline">
                        ({{ policy.effectiveStart }} -
                        {{ policy.effectiveEnd }})
                      </span>
                    </span>
                  </div>
                  <span
                    class="bg-lula-alt rounded-full text-white px-4 text-xs font-bold"
                  >
                    {{ (policy.broker || "").toUpperCase() }}
                  </span>
                </div>
              </div>
              <div
                class="bg-white border rounded text-center text-sm p-4"
                v-else
              >
                No Policies
              </div>
            </div>
          </div>
          <span />
          <div
            class="flex justify-end text-sm font-bold text-gray-600 mt-1 w-full"
          >
            <span
              class="cursor-pointer hover:underline mr-1"
              @click="newPolicy.toggle = true"
              v-if="!newPolicy.toggle && accountTab === 'price'"
            >
              Edit policies
            </span>
          </div>
          <div
            class="p-4 rounded-xl bg-gray-100 my-4 col-span-full"
            v-if="company.type === carAccountType && accountTab === 'price'"
          >
            <h2 class="text-xl font-bold text-gray-800 mb-2">API Settings</h2>
            <hr class="my-2" />
            <div class="mb-2">
              <label class="font-bold">Require Risk API</label>
              <br />
              <select v-model="company.riskApiEnabled" class="bg-white mt-1">
                <option :value="false">Disabled</option>
                <option :value="true">Enabled</option>
              </select>
            </div>
            <div class="mb-2">
              <label class="font-bold">Default Reservation Platform</label>
              <br />
              <input
                v-model="company.defaultReservationPlatform"
                class="bg-white mt-1"
              />
            </div>
            <div class="mb-2">
              <label class="font-bold">Service Account</label>
              <br />
              <input v-model="company.serviceAccount" class="bg-white mt-1" />
            </div>
          </div>
          <div
            class="p-4 rounded-xl bg-gray-100 my-4 col-span-full"
            v-if="accountTab === 'price'"
          >
            <h2 class="text-xl font-bold text-gray-800 mb-2">Product Prices</h2>
            <hr />
            <div class="mt-2">
              <div class="mb-2">
                <label class="font-bold">ACH Preferred</label>
                <br />
                <select v-model="company.achPreferred" class="bg-white mt-1">
                  <option :value="false">Disabled</option>
                  <option :value="true">Enabled</option>
                </select>
              </div>
              <div class="mb-2" v-if="company.type === carAccountType">
                <label class="font-bold">Onboarding Screen</label>
                <br />
                <select
                  v-model="company.onboardingScreen"
                  class="bg-white mt-1"
                >
                  <option :value="false">Disabled</option>
                  <option :value="true">Enabled</option>
                </select>
              </div>
              <div class="mb-2">
                <label class="font-bold">Auto Charge</label>
                <br />
                <select v-model="company.autoCharge" class="bg-white mt-1">
                  <option :value="false">Disabled</option>
                  <option :value="true">Enabled</option>
                </select>
              </div>
              <div class="mb-2">
                <label class="font-bold">Vehicle Subscription</label>
                <br />
                <div class="flex">
                  <div
                    class="flex items-center justify-center px-2 pt-px font-bold text-gray-800"
                  >
                    <span>$</span>
                  </div>
                  <input
                    type="number"
                    min="0"
                    step="0.01"
                    class="bg-white mt-1"
                    v-model="company.vehicleSubscriptionPrice"
                  />
                </div>
              </div>
              <div class="mb-2" v-if="company.type === truckingAccountType">
                <label class="font-bold">Daily Episode Price</label>
                <br />
                <div class="flex">
                  <div
                    class="flex items-center justify-center px-2 pt-px font-bold text-gray-800"
                  >
                    <span>$</span>
                  </div>
                  <input
                    type="number"
                    min="0"
                    step="0.01"
                    class="bg-white mt-1"
                    v-model="company.episodePrice"
                  />
                </div>
              </div>
              <div class="mb-2" v-if="company.type === carAccountType">
                <label class="font-bold">Roadside Assistance</label>
                <br />
                <div class="flex">
                  <div
                    class="flex items-center justify-center px-2 pt-px font-bold text-gray-800"
                  >
                    <span>$</span>
                  </div>
                  <input
                    type="number"
                    min="0"
                    step="0.01"
                    class="bg-white mt-1"
                    v-model="company.roadsideAssistancePrice"
                  />
                </div>
                <div class="flex justify-end">
                  <span
                    class="font-bold text-gray-600 cursor-pointer text-base hover:underline"
                    @click="company.roadsideAssistancePrice = null"
                  >
                    Remove Roadside Assistance
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-2 flex flex-col md:flex-row md:justify-end">
          <button
            @click="$router.go(-1)"
            type="button"
            class="bg-gray-100 hover:bg-gray-200 my-1 md:mr-1 md:my-0"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="bg-lula-gradient text-white my-1 hover:bg-lula-gradient-alt md:mr-1 md:my-0"
          >
            Save
          </button>
        </div>
      </form>
      <hr class="m-4" />
      <div class="w-full p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5">
        <div class="flex flex-col items-center mb-4 lg:mb-0">
          <span class="text-4xl mb-1">{{ metrics.activeVehicles }}</span>
          <label class="font-bold text-center text-md md:text-xl"
            >Active Vehicles</label
          >
        </div>
        <div class="flex flex-col items-center mb-4 lg:mb-0">
          <span class="text-4xl mb-1">{{ metrics.totalVehicles }}</span>
          <label class="font-bold text-center text-md md:text-xl"
            >Total Vehicles</label
          >
        </div>
        <div class="flex flex-col items-center mb-4 lg:mb-0">
          <div class="flex items-center" v-if="editSubscriptionSpots">
            <span
              class="text-4xl mx-6 cursor-pointer font-bold text-red-400 mb-2"
              @click="company.spots -= 1"
              v-if="company.spots > metrics.activeVehicles"
            >
              -
            </span>
            <span
              class="text-4xl mx-6 cursor-default font-bold text-gray-600 select-none mb-2"
              v-else
            >
              -
            </span>
            <span class="text-4xl mb-1">
              {{ company.spots || 0 }}
            </span>
            <span
              class="text-4xl mx-6 cursor-pointer font-bold text-green-500 mb-2"
              @click="company.spots += 1"
            >
              +
            </span>
          </div>
          <span class="text-4xl mb-1" v-else>{{ company.spots || 0 }}</span>
          <label class="font-bold text-center text-md md:text-xl"
            >Subscription Spots</label
          >
        </div>
        <div class="flex flex-col items-center mb-4 lg:mb-0">
          <span class="text-4xl mb-1">${{ metrics.subscriptionCost }}</span>
          <label class="font-bold text-center text-md md:text-xl"
            >Subscription Cost</label
          >
        </div>
        <div
          class="flex flex-col items-center mb-4 lg:mb-0 md:col-span-2 lg:col-span-1"
        >
          <span class="text-4xl mb-1">${{ metrics.proratedAmount }}</span>
          <label class="font-bold text-center text-md md:text-xl"
            >Seat Cost</label
          >
        </div>
      </div>
      <div
        class="w-full flex justify-between items-end mt-2 flex-col md:flex-row"
      >
        <div
          @click="editSubscriptionSpots = !editSubscriptionSpots"
          v-if="
            currentCompanyStatus === 'Active' &&
            getLaunchDarklyFlag('admin.account')
          "
          class="font-bold cursor-pointer text-gray-800 hover:underline"
        >
          <span v-if="editSubscriptionSpots">Save subscription spots</span>
          <span v-else>Edit subscription spots</span>
        </div>
        <span v-else />
        <span v-if="company.apiKey" class="font-bold text-gray-800"
          >API KEY: {{ company.apiKey }}</span
        >
        <span
          v-else
          @click="generateApiKey"
          class="font-bold cursor-pointer text-gray-800 hover:underline"
          >Generate API Key</span
        >
      </div>
    </div>
    <div
      class="p-4 mt-4 flex justify-between items-center bg-white rounded-2xl border border-lula flex-wrap md:flex-wrap-none"
      v-if="bulkVehicles.length > 0"
    >
      <div class="w-full md:w-1/2 px-4">
        <p class="font-bold text-center md:text-left">
          Would you like to save {{ bulkVehicles.length }} vehicles?
        </p>
        <p class="font-bold text-center text-gray-600 md:text-left">
          {{ bulkOperation.new }} new | {{ bulkOperation.update }} updates |
          {{ bulkOperation.spots }}
          <span v-if="bulkOperation.spots > 0"
            >(${{ bulkOperation.prorate }})</span
          >
          new subscription spots
        </p>
      </div>
      <div
        class="w-full mt-2 flex justify-center md:justify-end md:w-1/2 md:mt-0"
      >
        <button
          class="mx-1 py-2 rounded-lg border hover:bg-gray-100"
          @click="saveVehicles"
        >
          Save Vehicles
        </button>
        <button
          class="mx-1 py-2 rounded-lg border hover:bg-gray-100"
          @click="bulkVehicles = []"
        >
          Discard Vehicles
        </button>
      </div>
    </div>
    <div class="p-2 mt-4" v-if="getLaunchDarklyFlag('admin.policy')">
      <div class="flex justify-between items-end mb-2">
        <h2
          class="font-bold text-3xl mb-2 text-gray-800 md:text-5xl dark:text-white"
        >
          Application Approval
        </h2>
      </div>
      <div class="p-4 bg-white rounded-lg mt-4">
        <h2 class="font-bold text-gray-800 text-2xl mb-2">
          Application Status:
          {{
            company.applicationApproved === true
              ? "Approved"
              : company.applicationApproved === undefined
                ? "Not Set"
                : "Declined"
          }}
        </h2>
        <button
          @click="applicationApprove(true, company.id)"
          class="bg-lula-gradient text-white hover:bg-lula-gradient-alt lg:block"
        >
          Approve Application
        </button>
        <button
          @click="applicationApprove(false, company.id)"
          class="bg-lula-gradient text-white hover:bg-lula-gradient-alt lg:block float-right -mt-12"
        >
          Decline Application
        </button>
      </div>
    </div>
    <div class="p-4 bg-white rounded-lg mt-4">
      <h2 class="font-bold text-gray-800 text-2xl mb-2">Comments</h2>
      <textarea
        rows="5"
        :disabled="!editComments"
        v-model="company.comments"
        class="rounded-lg"
      />
      <div class="flex justify-between font-bold text-gray-800">
        <span />
        <span
          class="cursor-pointer hover:underline"
          @click="editComments = false"
          v-if="editComments"
        >
          Save comments
        </span>
        <span
          class="cursor-pointer hover:underline"
          @click="editComments = true"
          v-else
        >
          Edit comments
        </span>
      </div>
    </div>
    <div class="p-2 mt-4">
      <div class="flex justify-between items-end mb-2">
        <h2
          class="font-bold text-3xl mb-2 text-gray-800 md:text-5xl dark:text-white"
        >
          Company Documents
        </h2>
        <input
          type="file"
          ref="companyDocumentUpload"
          class="hidden"
          accept="application/pdf, image/jpeg, image/png,"
          multiple
        />
        <a
          :href="`https://paddocks.lula.com/company/${company.entityId}/documents`"
          class="bg-lula-gradient text-white hover:bg-lula-gradient-alt lg:block"
        >
          View Documents
        </a>
        <button
          @click="companyDocumentUpload.click()"
          class="hidden bg-lula-gradient text-white hover:bg-lula-gradient-alt lg:block"
        >
          Upload Document
        </button>
      </div>
      <div>
        <div v-if="companyDocuments.length > 0">
          <div
            class="bg-white rounded-lg text-gray-800 p-4 my-1"
            :class="{ 'bg-lula-light': doc.customMetadata?.active === 'true' }"
            v-for="doc in companyDocuments"
            :key="doc.fullPath"
          >
            <div class="flex justify-between">
              <div>
                <span
                  class="text-gray-800 text-base md:text-lg font-bold cursor-pointer hover:underline"
                  @click="selectCompanyDocument(doc)"
                >
                  {{ doc.name }}
                </span>
                <br />
                <span class="text-base md:text-xl">
                  {{ doc.updated }}
                </span>
              </div>
              <div>
                <div class="flex justify-end mb-2">
                  <div v-if="company.type === carAccountType">
                    <span
                      class="font-bold mx-2 cursor-pointer hover:underline"
                      v-if="doc.customMetadata?.active === 'true'"
                      @click="editCompanyDocument(doc, 'deactivate')"
                    >
                      Deactivate
                    </span>
                    <span
                      class="font-bold mx-2 cursor-pointer hover:underline"
                      v-else
                      @click="editCompanyDocument(doc, 'activate')"
                    >
                      Activate
                    </span>
                  </div>
                  <span
                    class="font-bold mx-2 cursor-pointer hover:underline"
                    @click="editCompanyDocument(doc, 'archive')"
                  >
                    Archive
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="bg-white rounded-lg text-center text-gray-600 p-4 select-none"
          v-else
        >
          No Company Documents
        </div>
      </div>
      <button
        @click="companyDocumentUpload.click()"
        class="w-full bg-lula-gradient text-white hover:bg-lula-gradient-alt mt-2 lg:hidden"
      >
        Upload Document
      </button>
    </div>
    <div class="p-2 mt-4" v-if="company.type === truckingAccountType">
      <div class="flex justify-between items-center mb-2">
        <h2
          class="font-bold text-3xl mb-2 text-gray-800 md:text-5xl dark:text-white"
        >
          Insurance Documents
        </h2>
        <button
          @click="
            $router.push({
              name: 'InsuranceDocumentDetail',
              params: {
                companyId: company.id,
                documentId: 'new',
              },
            })
          "
          class="hidden bg-lula-gradient text-white hover:bg-lula-gradient-alt lg:block"
        >
          Add Document
        </button>
      </div>
      <div>
        <grid
          v-if="insuranceDocuments.length > 0"
          @click="selectInsuranceDocument"
          :rows="insuranceDocuments"
          :columns="insuranceDocumentColumns"
        />
        <div
          class="bg-white rounded-lg text-center text-gray-600 p-4 select-none"
          v-else
        >
          No Insurance Documents
        </div>
      </div>
      <button
        @click="
          $router.push({
            name: 'InsuranceDocumentDetail',
            params: {
              companyId: company.id,
              documentId: 'new',
            },
          })
        "
        class="w-full bg-lula-gradient text-white mt-2 hover:bg-lula-gradient-alt lg:hidden"
      >
        Add Document
      </button>
    </div>
    <div class="p-2 mt-4">
      <div class="flex justify-between items-center mb-1">
        <div>
          <h2
            class="font-bold text-3xl text-gray-800 sm:mb-2 dark:text-white"
            :class="{
              'md:text-5xl': company.type !== carAccountType,
              'md:text-4xl': company.type === carAccountType,
            }"
          >
            Vehicles
          </h2>
          <div
            class="hidden text-base text-gray-500 pl-1 lg:text-xl sm:flex"
            v-if="company.type === carAccountType"
          >
            <div
              class="mr-4 cursor-pointer hover:text-gray-600 dark:text-white"
              @click="vehicleFilter = null"
            >
              <span class="px-1"> All Vehicles </span>
              <div
                style="height: 2.5px"
                class="bg-lula mt-px w-full rounded-full"
                v-if="!vehicleFilter"
              />
            </div>
            <div
              class="mr-4 cursor-pointer hover:text-gray-600 dark:text-white"
              @click="vehicleFilter = 'missing-info'"
            >
              <span class="px-1"> Missing Information </span>
              <div
                style="height: 2.5px"
                class="bg-lula mt-px w-full rounded-full"
                v-if="vehicleFilter === 'missing-info'"
              />
            </div>
            <div
              class="mr-4 cursor-pointer hover:text-gray-600 dark:text-white"
              @click="vehicleFilter = 'missing-coi'"
            >
              <span clas="px-1"> Missing Certificates </span>
              <div
                style="height: 2.5px"
                class="bg-lula mt-px w-full rounded-full"
                v-if="vehicleFilter === 'missing-coi'"
              />
            </div>
          </div>
        </div>
        <button
          @click="addVehicle"
          class="bg-lula-gradient hover:bg-lula-gradient-alt text-white hidden md:block"
        >
          Add Vehicle
        </button>
      </div>
      <div
        v-if="isTelematicsLoading"
        class="bg-white rounded-lg p-4 text-center text-gray-600 select-none mt-2"
      >
        Loading..
      </div>
      <grid
        v-else-if="!vehicleFilter && vehiclesTelematicsInfo.length > 0"
        :rows="vehiclesTelematicsInfo"
        :columns="vehicleColumns"
        :search="true"
        @click="selectVehicle"
        placeholder="Search Vehicle"
      />
      <grid
        v-else-if="!vehicleFilter && vehiclesTelematicsInfo.length === 0"
        :rows="vehicles"
        :columns="vehicleColumns"
        :search="true"
        @click="selectVehicle"
        placeholder="Search Vehicle"
      />
      <grid
        v-else-if="
          vehicleFilter === 'missing-info' && vehiclesMissingInfo.length > 0
        "
        :rows="vehiclesMissingInfo"
        :columns="vehicleMissingInfoColumns"
        :search="true"
        placeholder="Search Vehicle"
        @click="selectVehicle"
      />
      <grid
        v-else-if="
          vehicleFilter === 'missing-coi' &&
          vehiclesMissingCertificates.length > 0
        "
        :rows="vehiclesMissingCertificates"
        :columns="vehicleColumns"
        :search="true"
        placeholder="Search Vehicle"
        @click="selectVehicle"
      />
      <div
        v-else
        class="bg-white rounded-lg p-4 text-center text-gray-600 select-none mt-2"
      >
        No Vehicles
      </div>
      <button
        @click="
          $router.push({
            name: 'Vehicle',
            params: { companyId: company.id, vehicleId: 'new' },
          })
        "
        class="bg-lula-gradient text-sm w-full hover:bg-lula-gradient-alt text-white mt-2 md:hidden"
      >
        Add Vehicle
      </button>
      <div class="p-2 flex flex-row justify-between" v-if="!vehicleFilter">
        <input type="file" ref="vehicleImport" accept=".csv" class="hidden" />
        <span
          @click="importVehicles"
          class="text-gray-600 text-sm md:text-base font-bold cursor-pointer hover:underline dark:text-white"
          v-if="getLaunchDarklyFlag('admin.vehicle')"
        >
          Import Vehicles
        </span>
        <div v-if="vehicles.length > 0" class="flex justify-end">
          <span
            @click="exportVehicles"
            class="text-gray-600 text-sm md:text-base font-bold cursor-pointer hover:underline dark:text-white"
          >
            Export Vehicles
          </span>
        </div>
      </div>
    </div>
    <div class="p-2 mt-4">
      <div class="flex justify-between items-end mb-1">
        <h2
          class="font-bold text-3xl mb-2 text-gray-800 md:text-5xl dark:text-white"
        >
          Drivers
        </h2>
        <button
          @click="
            $router.push({
              name: 'Driver',
              params: { companyId: company.id, driverId: 'new' },
            })
          "
          class="bg-lula-gradient hover:bg-lula-gradient-alt text-white hidden md:block"
        >
          Add Driver
        </button>
      </div>
      <grid
        v-if="drivers.length > 0"
        :rows="drivers"
        :columns="driverColumns"
        :search="true"
        @click="selectDriver"
        placeholder="Search Driver"
      />
      <div
        v-else
        class="bg-white rounded-lg p-4 text-center text-gray-600 select-none mt-2"
      >
        No Drivers
      </div>
      <button
        @click="
          $router.push({
            name: 'Driver',
            params: { companyId: company.id, driverId: 'new' },
          })
        "
        class="bg-lula-gradient hover:bg-lula-gradient-alt text-white text-sm w-full mt-2 md:hidden"
      >
        Add Driver
      </button>
      <div v-if="drivers.length > 0" class="flex justify-end p-2">
        <span
          @click="exportDrivers"
          class="text-gray-600 text-sm md:text-base font-bold cursor-pointer hover:underline dark:text-white"
        >
          Export Drivers
        </span>
      </div>
    </div>
    <div class="p-2 mt-4">
      <div class="flex justify-between items-end mb-1">
        <h2
          class="font-bold text-3xl mb-2 text-gray-800 md:text-5xl dark:text-white"
        >
          Loss Runs
        </h2>
        <button
          @click="downloadLossRunReport()"
          class="bg-lula-gradient text-white hover:bg-lula-gradient-alt"
        >
          Download
        </button>
      </div>
    </div>
    <div class="p-2 mt-4">
      <div class="flex justify-between items-end mb-1">
        <h2
          class="font-bold text-3xl mb-2 text-gray-800 md:text-5xl dark:text-white"
        >
          First Party Claims
        </h2>
        <button
          @click="
            $router.push({
              name: 'Claim',
              params: {
                tenant: company.entityId,
                id: 'new',
              },
            })
          "
          class="bg-lula-gradient text-white hover:bg-lula-gradient-alt"
        >
          File Claim
        </button>
      </div>
      <div class="mt-4">
        <div
          :class="{ hidden: claimsGridLoaded }"
          class="bg-white p-4 rounded border"
        >
          <div class="bg-gray-100 rounded h-24 animate-pulse" />
        </div>
        <div ref="claimsGridRef" />
      </div>
    </div>
    <div class="p-2 mt-4">
      <div class="flex justify-between items-end mb-1">
        <h2
          class="font-bold text-3xl mb-2 text-gray-800 md:text-5xl dark:text-white"
        >
          Third Party Claims
        </h2>
      </div>
      <div class="mt-4">
        <div
          :class="{ hidden: tpClaimsGridLoaded }"
          class="bg-white p-4 rounded border"
        >
          <div class="bg-gray-100 rounded h-24 animate-pulse" />
        </div>
        <div ref="tpClaimsGridRef" />
      </div>
    </div>
    <div class="p-2 mt-4">
      <div class="flex justify-between items-end mb-1">
        <h2
          class="font-bold text-3xl mb-2 text-gray-800 md:text-5xl dark:text-white"
        >
          Reservation Coverage
        </h2>
      </div>
      <grid
        v-if="episodes.length > 0"
        :rows="episodes"
        :columns="episodeColumns"
        :search="true"
        placeholder="Search Episode"
        @click="selectEpisode"
      />
      <div
        v-else
        class="bg-white rounded-lg p-4 text-center text-gray-600 select-none mt-2"
      >
        No Reservations
      </div>
    </div>

    <div class="flex justify-between items-end mb-2">
      <h2
        class="font-bold text-3xl mb-2 text-gray-800 md:text-5xl dark:text-white"
      >
        Additional Users
      </h2>
      <button
        @click="addAdditionalUser"
        class="hidden bg-lula-gradient text-white hover:bg-lula-gradient-alt lg:block"
        v-if="getLaunchDarklyFlag('admin.account')"
      >
        Add User
      </button>
    </div>
    <div>
      <div v-if="additionalUsers.length > 0">
        <div
          class="bg-white rounded-lg text-gray-800 p-4 my-1"
          v-for="user in additionalUsers"
          :key="user.id"
        >
          <div class="flex justify-between">
            <div>
              <span class="text-base md:text-xl">
                {{ user.email }}
              </span>
            </div>
            <div>
              <div
                class="flex justify-end mb-2"
                v-if="getLaunchDarklyFlag('admin.account')"
              >
                <span
                  class="font-bold mx-2 cursor-pointer hover:underline"
                  @click="removeAdditionalUser(user.email)"
                >
                  Remove
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="bg-white rounded-lg text-center text-gray-600 p-4 select-none"
        v-else
      >
        No Additional Users
      </div>
      <button
        @click="addAdditionalUser"
        class="w-full bg-lula-gradient text-white hover:bg-lula-gradient-alt lg:hidden mt-1"
        v-if="getLaunchDarklyFlag('admin.account')"
      >
        Add User
      </button>
    </div>

    <div class="p-2 mt-4">
      <div class="flex justify-between items-end mb-1">
        <h2
          class="font-bold text-3xl mb-2 text-gray-800 md:text-5xl dark:text-white"
        >
          Connections
        </h2>
      </div>
      <div>
        <grid
          v-if="connections.length > 0"
          :rows="connections"
          :columns="[
            { name: 'id', id: 'id', hidden: true },
            { name: 'Name', id: 'name', hidden: false },
            { name: 'Created By', id: 'createdBy', hidden: false },
            { name: 'Created At', id: 'created', hidden: false },
          ]"
        />
        <div
          class="bg-white rounded-lg text-center text-gray-600 p-4 select-none"
          v-else
        >
          No Connections
        </div>
      </div>
    </div>
    <div class="p-2 mt-4">
      <div class="flex justify-between items-end mb-1">
        <h2
          class="font-bold text-3xl mb-2 text-gray-800 md:text-5xl dark:text-white"
        >
          Integrations
        </h2>
      </div>
      <div class="flex">
        <table
          class="bg-white rounded-lg table-auto w-full text-gray-600 text-lg"
        >
          <caption class="hidden">
            Show the service provider details
          </caption>
          <thead>
            <tr>
              <th class="text-left text-gray-800">Service Provider</th>
              <th class="hidden text-left text-gray-800 md:table-cell">
                Connection Status
              </th>
              <th class="hidden text-left text-gray-800 md:table-cell">
                Actions
              </th>
            </tr>
            <tr
              v-for="integration in integrations"
              :key="integration.id"
              class="py-4"
            >
              <td class="py-2">
                <div class="flex justify-between items-center">
                  <span>{{ integration.name }}</span>
                </div>
              </td>
              <td class="hidden py-2 md:table-cell">
                <div>
                  <span v-if="!integration.isInitialized"> Loading... </span>
                  <span v-else-if="integration.isConnected"> Connected </span>
                  <span v-else> Not Connected </span>
                </div>
              </td>
              <td class="hidden py-2 md:table-cell buttonRow">
                <button
                  v-if="integration.isInitialized && integration.isConnected"
                  @click="() => disconnectTelematicsIntegration(integration.id)"
                  type="button"
                  class="bg-lula-gradient hover:bg-lula-gradient-alt px-4 py-2 rounded-full text-white text-sm"
                >
                  Disconnect
                </button>
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <div class="p-2 mt-4">
      <div class="flex justify-between items-end mb-1">
        <h2
          class="font-bold text-3xl mb-2 text-gray-800 md:text-5xl dark:text-white"
        >
          Changelog
        </h2>
      </div>
      <grid
        v-if="changelog.length > 0"
        @click="selectChangelog"
        :rows="changelog"
        :columns="changelogColumns"
      />
      <div
        class="bg-white rounded-lg text-center text-gray-600 p-4 select-none"
        v-else
      >
        {{
          changelogRefresh
            ? "Refreshing changelog..."
            : "No Company Change History"
        }}
      </div>
      <div class="flex justify-end">
        <span
          class="font-bold text-gray-600 cursor-pointer hover:underline dark:text-white"
          @click="refreshChangelog"
        >
          Refresh changelog
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { ref, onMounted, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ellipsisText, getChangelogDescription, formatEin } from "@/utils";
import moment from "moment-timezone";
import {
  initializeShadowDom,
  fetchFrontend,
  mountFrontend,
} from "@getaddify/lula-components";
import { AccessLevelTypeEnum } from "@getaddify/tenant-account-management";
import axios from "axios";

function getClaimsFormMode(type) {
  switch (type) {
    case process.env["VUE_APP_ACCOUNT_TYPE_TRUCKING"]: {
      return "admin_trucks";
    }
    case process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"]:
    default: {
      return "admin_cars";
    }
  }
}

function getFieldsForAccountType(accountType) {
  const commonFields = [
    "businessLegalName",
    "businessEmail",
    "businessPhone",
    "demo",
    "billingDate",
    "serviceStart",
    "contactFirstName",
    "contactLastName",
    "contactNumber",
    "contactEmail",
    "contactPosition",
    "created",
    "modified",
  ];
  switch (accountType) {
    case process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"]: {
      return [...commonFields, "einNumber", "contactLicense", "abiIntegration"];
    }
    case process.env["VUE_APP_ACCOUNT_TYPE_TRUCKING"]: {
      return [
        ...commonFields,
        "mcNumber",
        "dotNumber",
        "operationalSince",
        "authorityType",
        "initialInvoice",
        "keeptruckinApiKey",
      ];
    }
    default: {
      return [];
    }
  }
}

function getSubscriptionCost(
  vehicles,
  subscriptionCost,
  roadsideAssistance = 0,
) {
  let cost = 0;
  vehicles.forEach((vin) => {
    if (vin["status"] === "Active") {
      if (vin.override) {
        cost +=
          parseFloat(vin.override || 0) + parseFloat(roadsideAssistance || 0);
      } else {
        cost +=
          parseFloat(isNaN(subscriptionCost) ? 0 : subscriptionCost || 0) +
          parseFloat(roadsideAssistance || 0);
      }
    }
  });
  return parseFloat(cost).toFixed(2);
}

export default {
  computed: {
    AccessLevelTypeEnum() {
      return AccessLevelTypeEnum;
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const company = ref({});
    const currentCompanyStatus = ref(null);

    const accountTab = ref("account");

    const vehicles = ref([]);
    const vehicleAccountStatus = ref([]);
    const vehiclesTelematicsInfo = ref([]);
    const drivers = ref([]);
    const episodes = ref([]);
    const connections = ref([]);
    const reservations = ref([]);
    const renters = ref([]);
    const policies = ref([]);
    const vehicleDocuments = ref([]);
    const changelog = ref([]);
    const isTelematicsConnected = ref(false);
    const isTelematicsLoading = ref(true);

    const newPolicy = ref({});
    const changelogRefresh = ref(false);
    const vehicleImport = ref(null);
    const editStripeId = ref(false);
    const bulkVehicles = ref([]);
    const editComments = ref(false);
    const editSubscriptionSpots = ref(false);
    const confirmActivation = ref({
      confirm: false,
      effectiveDate: moment()
        .tz("America/New_York")
        .add(1, "days")
        .startOf("day")
        .add(1, "minutes")
        .format("yyyy-MM-DD"),
      autoCharge: false,
    });

    watch(editComments, async () => {
      if (!editComments.value) {
        toast("Saving comments");
        try {
          await store.dispatch("saveCommentsForCompany", {
            companyId: company.value.id,
            comments: company.value.comments,
          });
          toast.clear();
          toast("Comments saved.");
        } catch (err) {
          toast.clear();
          toast("Failed to save comments.");
        }
      }
    });

    const insuranceDocuments = ref([]);
    onMounted(async () => {
      insuranceDocuments.value = await store.dispatch(
        "getCompanyInsuranceDocuments",
        {
          companyId: route.params.id,
        },
      );
    });

    const integrations = ref([
      {
        id: "bouncie",
        name: "Bouncie",
        isInitialized: false,
        isConnected: false,
      },
      {
        id: "zubie",
        name: "Zubie",
        isInitialized: false,
        isConnected: false,
      },
      {
        id: "motive",
        name: "Motive",
        isInitialized: false,
        isConnected: false,
      },
      {
        id: "samsara",
        name: "Samsara",
        isInitialized: false,
        isConnected: false,
      },
    ]);

    async function refreshTelematicsProviderStatus() {
      const telematicsResponse = await store.dispatch(
        "getTelematicsAccountStatus",
        company.value.entityId,
      );

      if (!telematicsResponse.success) {
        console.error(
          "[CMPDTL] Cannot get the current Telematics account status. Error: ",
          telematicsResponse.error,
        );
        toast(
          "There is an error while getting the current Telematics account status. See the console for details.",
        );
        return;
      }

      const telematicsAccount = telematicsResponse.data;

      integrations.value.forEach((integration) => {
        const accountIntegration = telematicsAccount.integrations.find(
          (item) => item.type === integration.id,
        );

        if (accountIntegration && accountIntegration.status === "connected") {
          isTelematicsConnected.value = true;
          integration.isConnected = true;
        }

        integration.isInitialized = true;
      });
    }

    function refreshDocuments() {
      store
        .dispatch("getCompanyDocumentList", {
          accountId: route.params.id,
          vehicleId: route.params.vehicleId,
        })
        .then((vDocs) => (companyDocuments.value = vDocs));
    }

    const companyDocuments = ref([]);
    const companyDocumentUpload = ref(null);

    const metrics = computed(() => {
      const year = parseInt(moment().utc().add(1, "M").format("yyyy"));
      const month = parseInt(moment().utc().add(1, "M").format("MM"));
      const tenantTimeZone =
        moment.tz.zone(company.value.timeZone) != null
          ? company.value.timeZone
          : "America/New_York";
      const billTowardsDate = moment()
        .tz(tenantTimeZone)
        .set({ hour: 0, minute: 1, second: 0, millisecond: 0 })
        .format();

      let daysUntilNextBillingCycle = Math.abs(
        parseInt(
          moment
            .utc(company.value.billingDate)
            .set("year", year)
            .set("month", month - 1)
            .diff(billTowardsDate, "days"),
        ),
      );
      if (
        parseInt(moment().format("DD")) <
        parseInt(moment.utc(company.value.billingDate).format("DD"))
      ) {
        daysUntilNextBillingCycle = Math.abs(
          parseInt(
            moment
              .utc(company.value.billingDate)
              .set("year", parseInt(moment().format("yyyy")))
              .set("month", parseInt(moment().format("MM")) - 1)
              .diff(billTowardsDate, "days"),
          ),
        );
      }
      const monthlyRate =
        parseFloat(company.value.vehicleSubscriptionPrice || 0) +
        parseFloat(company.value.roadsideAssistancePrice || 0);

      return {
        activeVehicles: vehicles.value.filter(
          (vin) => vin["status"] === "Active",
        ).length,
        totalVehicles: vehicles.value.length,
        subscriptionCost: getSubscriptionCost(
          vehicles.value,
          company.value.vehicleSubscriptionPrice,
          company.value.roadsideAssistancePrice,
        ),
        proratedAmount:
          daysUntilNextBillingCycle > 0
            ? parseFloat(
                daysUntilNextBillingCycle *
                  (monthlyRate / moment().daysInMonth()),
              ).toFixed(2)
            : monthlyRate,
      };
    });

    const bulkOperation = computed(() => {
      const operation = { new: 0, update: 0, spots: 0, prorate: 0 };
      const merged = [
        ...vehicles.value.map((vehicle) => {
          const mergedVehicle = bulkVehicles.value.find(
            (b) => b.id === vehicle.id,
          );
          if (mergedVehicle) {
            return { ...vehicle, ...mergedVehicle };
          }
          return vehicle;
        }),
        ...bulkVehicles.value.filter((v) => !v.id),
      ];
      operation.new = bulkVehicles.value.filter((v) => !v.id).length;
      operation.update = vehicles.value.filter((v) =>
        bulkVehicles.value.find((b) => b.id === v.id),
      ).length;
      if (
        merged.filter((v) => v.status === "Active").length > company.value.spots
      ) {
        operation.spots =
          merged.filter((v) => v.status === "Active").length -
          company.value.spots;
      }
      if (operation.spots) {
        operation.prorate = parseFloat(
          operation.spots * metrics.value.proratedAmount,
        ).toFixed(2);
      }
      return operation;
    });

    onMounted(async () => {
      company.value = await store.dispatch("getCompany", route.params.id);
      await refreshTelematicsProviderStatus();
      vehicles.value = await store.dispatch(
        "getVehiclesForCompany",
        company.value.id,
      );

      reservations.value = await store.dispatch(
        "getReservationsForCompany",
        company.value.id,
      );
      renters.value = await store.dispatch(
        "getRentersForCompany",
        company.value.id,
      );
      drivers.value = await store.dispatch(
        "getDriversForCompany",
        company.value.id,
      );
      episodes.value = await store.dispatch(
        "getEpisodesForCompany",
        company.value.id,
      );
      connections.value = await store.dispatch(
        "getConnectionsForCompany",
        company.value.id,
      );
      policies.value = await store.dispatch("getSystemPolicies");
      await store.dispatch("subscribeVehicles", {
        companyId: company.value.id,
        subscription: (subVehicles) => {
          vehicles.value = subVehicles;
          console.log("[CMPDTL] subscription updated");
        },
      });
      changelog.value = await store.dispatch(
        "getCompanyChangelog",
        company.value.id,
      );

      const vehicleDocs = await Promise.all(
        vehicles.value.map((vehicle) => {
          return store.dispatch("getVehicleDocumentList", {
            accountId: company.value.id,
            vehicleId: vehicle.id,
          });
        }),
      );
      vehicleDocuments.value = vehicleDocs.flat();

      if (
        await store.dispatch(
          "getLaunchDarklyFlag",
          "telematics-status-dashboard",
        )
      ) {
        const vehicleResponse = await store.dispatch(
          "getVehicleAccountStatus",
          company.value.entityId,
        );
        if (!vehicleResponse.success) {
          console.error(
            "[CMPDTL] Cannot get the current vehicle account status. Error: ",
            vehicleResponse.error,
          );
          toast(
            "There is an error while getting the current vehicle account status. See the console for details.",
          );
          return;
        }
        vehicleAccountStatus.value = vehicleResponse.data;

        if (vehicleAccountStatus.value.length > 0) {
          vehiclesTelematicsInfo.value = vehicles.value.map((vehicle) => {
            const vehicleTelematicsStatus = vehicleAccountStatus.value.find(
              (vehicleAccount) => vehicleAccount.vin === vehicle.vin,
            );
            const integrationForVehicle = integrations.value.find(
              (integration) =>
                vehicleTelematicsStatus.eldProvider === integration.id,
            );
            const isVehicleConnected = integrationForVehicle
              ? integrationForVehicle.isConnected
              : false;
            const telematicsState =
              vehicleTelematicsStatus &&
              isVehicleConnected &&
              vehicleTelematicsStatus.lastEldUpdate
                ? "Connected"
                : isTelematicsConnected.value
                  ? "Pending"
                  : "Not Connected";

            return { ...vehicle, telematics: telematicsState };
          });
        } else {
          console.log("[CMPDTL] vehicles account status is null");
          vehiclesTelematicsInfo.value = vehicles.value;
        }
        isTelematicsLoading.value = false;
      } else {
        // Can remove this once flag is gone.
        console.log("[CMPDTL] vehicle account status is not called");
        vehiclesTelematicsInfo.value = vehicles.value;
        isTelematicsLoading.value = false;
      }
    });

    watch(editSubscriptionSpots, async () => {
      if (editSubscriptionSpots.value === false) {
        if (
          !confirm(
            "You are about to modify subscription spots for this account, please remember that these spots are NOT billed when adjusting them manually. Are you sure you want to continue?",
          )
        ) {
          // revert spots if the user declines to save.
          const comp = await store.dispatch("getCompany", company.value.id);
          company.value.spots = comp.spots;
          return;
        }

        toast("Saving subscription spots.");
        try {
          company.value = await store.dispatch("saveCompanySubscriptionSpots", {
            company: { ...company.value },
          });
          toast.clear();
          toast("Subscription spots saved.");
        } catch (err) {
          toast.clear();
          toast("Failed to save spots.");
        }
      }
    });

    watch(company, async () => {
      company.value.demo = company.value.demo || false;
      company.value.enableOverride = company.value.enableOverride || false;
      company.value.achPreferred = company.value.achPreferred || false;
      company.value.onboardingScreen =
        typeof company.value?.onboardingScreen === "undefined"
          ? true
          : company.value.onboardingScreen;
      company.value.riskApiEnabled = company.value.riskApiEnabled || false;
      company.value.serviceAgreementSpots =
        company.value.serviceAgreementSpots || 0;
      company.value.vehicleSubscriptionPrice =
        company.value.vehicleSubscriptionPrice || null;
      company.value.serviceStart = company.value.serviceStart
        ? moment.utc(company.value.serviceStart).format("yyyy-MM-DD")
        : null;
      company.value.billingDate = company.value.billingDate
        ? moment.utc(company.value.billingDate).format("yyyy-MM-DD")
        : null;
    });

    watch(company, () => {
      company.value.created = company.value.created
        ? moment(company.value.created).format("yyyy-MM-DD")
        : "";
      company.value.modified = company.value.modified
        ? moment(company.value.modified).format("yyyy-MM-DD")
        : "";
      company.value.operationalSince = company.value.operationalSince
        ? moment(company.value.operationalSince).format("yyyy-MM-DD")
        : "";
    });

    const additionalUsers = ref([]);
    onMounted(async () => {
      try {
        additionalUsers.value = await store.dispatch(
          "getAdditionalUsersForAccount",
          route.params.id,
        );
      } catch (ex) {
        console.log(ex);
        additionalUsers.value = [];
      }
    });

    const creditWalletContainerRef = ref(null);
    const isCreditWalletComponentLoading = ref(false);

    const processCreditOperation = (creditAmount, invoiceId) => {
      const creditInfo = {
        tenantId: company.value.entityId,
        creditAmount,
        invoiceId,
      };

      return store.dispatch("creditWalletFunds", { creditInfo });
    };

    watch(creditWalletContainerRef, async () => {
      if (creditWalletContainerRef.value) {
        isCreditWalletComponentLoading.value = true;

        const [shadowDom, container] = initializeShadowDom(
          creditWalletContainerRef.value,
        );
        await fetchFrontend(shadowDom, process.env["VUE_APP_BILLING_UI_URL"]);

        mountFrontend("credit-wallet", container, {
          onDone: () => toast("The customer wallet funds were credited"),
          processCreditOperation,
        });

        isCreditWalletComponentLoading.value = false;
      }
    });

    const claimsGridLoaded = ref(false);
    const claimsGridRef = ref(null);

    const tpClaimsGridLoaded = ref(false);
    const tpClaimsGridRef = ref(null);

    onMounted(async () => {
      company.value = await store.dispatch("getCompany", route.params.id);
      currentCompanyStatus.value = company.value.status;
      await new Promise((r) => setTimeout(r, 100));
      const [claimsGridShadow, claimsGridRoot] = initializeShadowDom(
        claimsGridRef.value,
      );
      const manifest = await fetchFrontend(
        claimsGridShadow,
        process.env["VUE_APP_CLAIMS_UI_URL"],
      );
      mountFrontend("claims-grid", claimsGridRoot, {
        environment: process.env["VUE_APP_ENVIRONMENT"],
        userId: store.state.user?.sub,
        userEmail: store.state.user?.email,
        graphUrl: process.env["VUE_APP_CLAIMS_GRAPH"],
        graphHeaders: {
          Authorization: `Bearer ${await store.dispatch("getIdentityToken")}`,
        },
        mode: getClaimsFormMode(store.state.type),
        tenantId: company.value.entityId,
        vehicles: await store.dispatch(
          "getVehiclesForCompany",
          company.value.id,
        ),
        onMounted: () => (claimsGridLoaded.value = true),
        selectClaim(evt, claim) {
          if (evt.metaKey) {
            const urlResolve = router.resolve({
              name: "Claim",
              params: {
                tenant: claim.tenant || claim.tenantId,
                id: claim.id,
              },
            });
            open(urlResolve.fullPath, "_blank");
          } else {
            router.push({
              name: "Claim",
              params: {
                tenant: claim.tenant || claim.tenantId,
                id: claim.id,
              },
            });
          }
        },
      });
      const [tpClaimsGridShadow, tpClaimsGridRoot] = initializeShadowDom(
        tpClaimsGridRef.value,
      );
      await fetchFrontend(
        tpClaimsGridShadow,
        process.env["VUE_APP_CLAIMS_UI_URL"],
        manifest,
      );
      mountFrontend("claims-grid", tpClaimsGridRoot, {
        environment: process.env["VUE_APP_ENVIRONMENT"],
        userId: store.state.user?.sub,
        userEmail: store.state.user?.email,
        graphUrl: process.env["VUE_APP_CLAIMS_GRAPH"],
        graphHeaders: {
          Authorization: `Bearer ${await store.dispatch("getIdentityToken")}`,
        },
        mode: getClaimsFormMode(store.state.type) + "_thirdparty",
        tenantId: company.value.entityId,
        vehicles: await store.dispatch(
          "getVehiclesForCompany",
          company.value.id,
        ),
        onMounted: () => (tpClaimsGridLoaded.value = true),
        selectClaim(evt, claim) {
          if (evt.metaKey) {
            const urlResolve = router.resolve({
              name: "Claim",
              params: {
                tenant: claim.tenant || claim.tenantId,
                id: claim.id,
              },
            });
            open(urlResolve.fullPath, "_blank");
          } else {
            router.push({
              name: "Claim",
              params: {
                tenant: claim.tenant || claim.tenantId,
                id: claim.id,
              },
            });
          }
        },
      });
      vehicleImport.value.addEventListener("change", async (evt) => {
        toast("Uploading bulk sheet for vehicles...");
        try {
          const file = evt.target.files[0];
          if (file) {
            await store.dispatch("saveVehicleUploadAttachment", {
              accountId: company.value.id,
              file,
            });

            const result = await store.dispatch("getBulkVehicles", {
              filePath: `accounts/${company.value.id}/vehicleBulkUpload/${file.name}`,
              contentType: file.type,
              strict: true,
            });
            if (!result.success) {
              throw new Error(`Failed to get vehicles: ${result.message}`);
            }

            bulkVehicles.value = result.data.vehicles;
            evt.target.value = "";
          }
        } catch (err) {
          toast("Failed to bulk update vehicles.");
        }
      });
      refreshDocuments();
      companyDocumentUpload.value?.addEventListener("change", async () => {
        const uploads = [];
        Array.from(companyDocumentUpload.value.files).forEach((file) => {
          uploads.push(
            store
              .dispatch("saveCompanyDocument", {
                accountId: company.value.id,
                file,
              })
              .then((fileDoc) => {
                store.dispatch("addCompanyChangelog", {
                  accountId: company.value.id,
                  entityId: fileDoc.metadata.fullPath,
                  eventType: "account-document-upload",
                  fieldName: "name",
                  oldValue: file.name,
                  newValue: file.name,
                });
              }),
          );
        });
        await Promise.all(uploads);
        refreshDocuments();
        companyDocumentUpload.value.value = null;
      });
    });

    // ***** Users *****
    const userAccountsRef = ref([]); // List of users for the given account.
    const allPermissionsRef = ref([]); // List of all permissions available in the system.

    const selectedUserAccountRef = ref(null); // The currently selected user account, if any.
    const selectedUserAccountEditingRef = ref(false); // Whether or not we are editing the currently selected user account.
    const selectedUserAccountAccessLevelRef = ref(null); // The access level of the current selected user account
    const selectedUserAccountAccessLevelDraftRef = ref(null); // The access level of the current selected user account, while editing

    const newUserAccountCreatingRef = ref(false); // Whether or not we are currently creating a new user account.
    const newUserAccountEmailAddressRef = ref(null); // The email address of the new user account, if any.
    const newUserAccountAccessLevelRef = ref(null); // The access level for the new user account
    const downloadingLossRuns = ref(false);
    /**
     * Maps permissions to a format more suitable for the frontend
     */
    const mapPermissions = (permissions) => {
      const reduced = permissions.reduce((map, permission) => {
        if (!map[permission.namespace]) {
          map[permission.namespace] = {
            key: permission.namespace,
            label:
              permission.namespace[0].toLocaleUpperCase() +
              permission.namespace.slice(1),
            description: {},
          };
        }
        map[permission.namespace].description[permission.privilege] =
          permission.description;
        return map;
      }, {});
      const values = [];
      // eslint-disable-next-line no-unused-vars
      for (const [_key, value] of Object.entries(reduced).values()) {
        values.push(value);
      }
      return values;
    };

    watch(company, async () => {
      const [allPermissions, userAccounts] = await Promise.all([
        store.dispatch("listPermissions"),
        store.dispatch("getCustomerUserAccountsByCustomerId", {
          customerId: company.value.entityId,
        }),
      ]);
      allPermissionsRef.value = mapPermissions(allPermissions);
      userAccountsRef.value = userAccounts.map((userAccount) => ({
        ...userAccount,
        added: moment(userAccount.createdAt).fromNow(),
      }));
    });

    watch(selectedUserAccountRef, async () => {
      selectedUserAccountEditingRef.value = false;
      if (selectedUserAccountRef.value == null) {
        selectedUserAccountAccessLevelRef.value = null;
        selectedUserAccountAccessLevelDraftRef.value = null;
        return;
      }

      selectedUserAccountAccessLevelRef.value = {
        type: selectedUserAccountRef.value.accessLevel.type,
      };
      selectedUserAccountAccessLevelDraftRef.value = {
        type: selectedUserAccountRef.value.accessLevel.type,
      };

      const permissions = {};
      selectedUserAccountRef.value.accessLevel.permissions?.forEach(
        (permission) => {
          const [namespace, privilege] = permission.split(":");
          switch (privilege) {
            case "read":
              permissions[namespace] = {
                ...permissions[namespace],
                read: true,
              };
              break;
            case "write":
              permissions[namespace] = {
                ...permissions[namespace],
                write: true,
              };
              break;
          }
        },
      );

      selectedUserAccountAccessLevelRef.value.permissions = permissions;
      selectedUserAccountAccessLevelDraftRef.value.permissions = JSON.parse(
        JSON.stringify(permissions),
      );
    });

    const onAddUserAccountSelected = async () => {
      selectedUserAccountRef.value = null;
      newUserAccountCreatingRef.value = true;
      newUserAccountEmailAddressRef.value = "";
      newUserAccountAccessLevelRef.value = {
        type: AccessLevelTypeEnum.Privileged,
        permissions: {},
      };
      onBulkPermissionsSelect("none");
    };

    // Triggered when one of the bulk edit buttons are selected. Sets the appropriate value
    // for all of the user account's draft permissions.
    const onBulkPermissionsSelect = (kind) => {
      const permissions = newUserAccountCreatingRef.value
        ? newUserAccountAccessLevelRef.value.permissions
        : selectedUserAccountAccessLevelDraftRef.value.permissions;

      const write = kind === "all";
      const read = kind === "all" || kind === "view";
      allPermissionsRef.value.forEach((permission) => {
        permissions[permission.key] = { read, write };
      });
    };

    // Triggered when the access level checkbox is clicked.
    // Toggles the access level value to the opposite and sets default permissions.
    const onAccessLevelTypeClicked = () => {
      const accessLevel = newUserAccountCreatingRef.value
        ? newUserAccountAccessLevelRef
        : selectedUserAccountAccessLevelDraftRef;
      if (accessLevel.value.type === AccessLevelTypeEnum.Privileged) {
        onBulkPermissionsSelect("all");
        accessLevel.value.type = AccessLevelTypeEnum.Owner;
      } else {
        onBulkPermissionsSelect("none");
        accessLevel.value.type = AccessLevelTypeEnum.Privileged;
      }
    };

    // Triggered when a permission checkbox is clicked. Toggles the permission value to the opposite
    // variation. If a write permission is enabled, we also enable the corresponding read.
    const onPermissionClicked = (namespace, privilege) => {
      const permissions = newUserAccountCreatingRef.value
        ? newUserAccountAccessLevelRef.value.permissions
        : selectedUserAccountAccessLevelDraftRef.value.permissions;

      const currentRead = permissions[namespace]?.read ?? false;
      const currentWrite = permissions[namespace]?.write ?? false;

      if (privilege === "read") {
        // Negate the read
        permissions[namespace] = {
          read: !currentRead,
          write: currentWrite,
        };
      } else {
        // If toggling off the write, just negate it
        if (currentWrite) {
          permissions[namespace] = {
            read: currentRead,
            write: false,
          };
          // Otherwise, select both read and write
        } else {
          permissions[namespace] = {
            read: true,
            write: true,
          };
        }
      }
    };

    // Triggered when the discard permission button is selected. Ends the editing session
    // and resets the draft permissions.
    const discardPermissionChanges = () => {
      selectedUserAccountEditingRef.value = false;
      selectedUserAccountAccessLevelDraftRef.value = JSON.parse(
        JSON.stringify(selectedUserAccountAccessLevelRef.value),
      );
    };

    // Triggered when the discard permission button is selected. Ends the editing session
    // and resets the draft permissions.
    const discardUserAccountCreation = () => {
      newUserAccountCreatingRef.value = false;
      newUserAccountEmailAddressRef.value = null;
      newUserAccountAccessLevelRef.value = null;
    };

    // Triggered when the save permissions button is selected. Persists the changes and ends the
    // editing session.
    const savePermissionChanges = async (emailAddress) => {
      try {
        const accessLevelType =
          selectedUserAccountAccessLevelDraftRef.value.type;
        let permissions;
        if (accessLevelType === AccessLevelTypeEnum.Privileged) {
          permissions = [];
          for (const [namespace] of Object.entries(
            selectedUserAccountAccessLevelDraftRef.value.permissions,
          )) {
            if (
              selectedUserAccountAccessLevelDraftRef.value.permissions[
                namespace
              ].write
            ) {
              permissions.push(`${namespace}:write`);
            }
            if (
              selectedUserAccountAccessLevelDraftRef.value.permissions[
                namespace
              ].read
            ) {
              permissions.push(`${namespace}:read`);
            }
          }
          if (permissions.length === 0) {
            toast(`User account must have at least one permission.`);
            return;
          }
        }
        const accessLevel = { type: accessLevelType, permissions: permissions };

        toast(`Saving permissions.`);
        await store.dispatch("changeCustomerUserAccountAccessLevel", {
          customerId: company.value.entityId,
          email: emailAddress,
          accessLevel,
        });
        selectedUserAccountEditingRef.value = false;
        selectedUserAccountAccessLevelRef.value =
          selectedUserAccountAccessLevelDraftRef.value;
        selectedUserAccountRef.value.accessLevel = accessLevel;

        toast.clear();
        toast(`Successfully saved permissions.`);
      } catch (ex) {
        let reason = ".";
        if (ex.response?.status >= 400 && ex.response?.status < 500) {
          const error = await ex.response.json();
          if (error.detail) {
            reason = `: ${error.detail}`;
          }
        }
        toast(`Failed to save permissions${reason}`);
        throw ex;
      }
    };

    const addUserAccount = async () => {
      try {
        let permissions;
        if (
          newUserAccountAccessLevelRef.value.type ===
          AccessLevelTypeEnum.Privileged
        ) {
          permissions = [];
          for (const [namespace] of Object.entries(
            newUserAccountAccessLevelRef.value.permissions,
          )) {
            if (
              newUserAccountAccessLevelRef.value.permissions[namespace].write
            ) {
              permissions.push(`${namespace}:write`);
            }
            if (
              newUserAccountAccessLevelRef.value.permissions[namespace].read
            ) {
              permissions.push(`${namespace}:read`);
            }
          }
          if (permissions.length === 0) {
            toast(`User account must have at least one permission.`);
            return;
          }
        }
        const accessLevel = {
          type: newUserAccountAccessLevelRef.value.type,
          permissions: permissions,
        };
        toast(`Adding new user.`);
        const userAccount = await store.dispatch("createCustomerUserAccount", {
          customerId: company.value.entityId,
          emailAddress: newUserAccountEmailAddressRef.value,
          accessLevel: accessLevel,
        });
        userAccountsRef.value.unshift({
          ...userAccount,
          added: moment(userAccount.createdAt).fromNow(),
        });
        newUserAccountCreatingRef.value = false;
        newUserAccountAccessLevelRef.value = null;
        newUserAccountEmailAddressRef.value = null;
        toast.clear();
        toast(`Successfully added a new user.`);
      } catch (ex) {
        toast.clear();
        let reason = ".";
        if (ex.response?.status >= 400 && ex.response?.status < 500) {
          const error = await ex.response.json();
          if (error.detail) {
            reason = `: ${error.detail}`;
          }
        }
        toast(`Failed to add a new user${reason}`);
        throw ex;
      }
    };

    const deleteUserAccount = async () => {
      try {
        if (!selectedUserAccountRef.value) {
          return;
        }

        const confirmed = window.confirm(
          `Are you sure want to remove ${selectedUserAccountRef.value.emailAddress} from ${selectedUserAccountRef.value.customerDisplayName}?`,
        );
        if (!confirmed) {
          return;
        }

        let userEmailAddress = selectedUserAccountRef.value.emailAddress;
        toast(`Removing user.`);
        await store.dispatch("deleteCustomerUserAccount", {
          customerId: company.value.entityId,
          emailAddress: userEmailAddress,
        });
        userAccountsRef.value = userAccountsRef.value.filter(
          (userAccount) => userAccount.emailAddress !== userEmailAddress,
        );
        selectedUserAccountRef.value = null;
        toast.clear();
        toast(`Removed user.`);
      } catch (ex) {
        toast.clear();
        let reason = ".";
        if (ex.response?.status >= 400 && ex.response?.status < 500) {
          const error = await ex.response.json();
          if (error.detail) {
            reason = `: ${error.detail}`;
          }
        }
        toast(`Failed to remove the user${reason}`);
        throw ex;
      }
    };

    // Routes to the corresponding user page
    const routeToUser = async (userAccount) => {
      router.push({
        name: "UserDetails",
        params: {
          emailAddress: userAccount.emailAddress,
        },
      });
    };

    return {
      claimsGridRef,
      tpClaimsGridRef,
      claimsGridLoaded,
      tpClaimsGridLoaded,
      integrations,
      creditWalletContainerRef,
      isCreditWalletComponentLoading,
      company,
      currentCompanyStatus,
      metrics,
      accountTab,
      bulkVehicles,
      bulkOperation,
      newPolicy,
      downloadingLossRuns,
      connections: computed(() => {
        return connections.value.map((con) => {
          return {
            ...con,
            created: moment(con).format("yyyy-MM-DD"),
          };
        });
      }),
      offRentPolicies: computed(() => {
        return policies.value.filter((policy) => {
          if (policy.account && policy.account !== company.value.entityId) {
            return false;
          }
          return policy.coverageUse === "off-rent";
        });
      }),
      onRentPolicies: computed(() => {
        return policies.value.filter((policy) => {
          if (policy.account && policy.account !== company.value.entityId) {
            return false;
          }
          return policy.coverageUse === "on-rent";
        });
      }),
      traditionalPolicies: computed(() => {
        return policies.value.filter((policy) => {
          if (policy.account && policy.account !== company.value.entityId) {
            return false;
          }
          return policy.coverageUse === "traditional";
        });
      }),
      async submitCompanyPolicy() {
        if (
          policies.value.find((p) => p.policyNumber === newPolicy.value.number)
        ) {
          alert("Coverage already exists.");
          return;
        }
        if (
          confirm(
            "Are you sure you want to add this Coverage? This cannot be undone.",
          )
        ) {
          const createdPolicy = await store.dispatch("saveSystemPolicy", {
            ...newPolicy.value,
            account: company.value.entityId,
          });
          router.push({
            name: "Policy",
            params: { policyId: createdPolicy.id },
          });
        }
      },
      async selectCompanyDocument(doc) {
        store.dispatch("logEvent", { name: "download_company_document" });
        const url = await store.dispatch("getFileDownloadUrl", doc.fullPath);
        window.open(url, "_blank");
      },
      async editCompanyDocument(document, action) {
        switch (action) {
          case "activate": {
            await store.dispatch("saveFileMeta", {
              filePath: document.fullPath,
              meta: {
                customMetadata: {
                  active: true,
                },
              },
            });
            store.dispatch("addCompanyChangelog", {
              accountId: company.value.id,
              entityId: document.fullPath,
              eventType: "account-document-meta",
              fieldName: "active",
              oldValue: null,
              newValue: true,
            });
            break;
          }
          case "deactivate": {
            await store.dispatch("saveFileMeta", {
              filePath: document.fullPath,
              meta: {
                customMetadata: {
                  active: null,
                },
              },
            });
            store.dispatch("addCompanyChangelog", {
              accountId: company.value.id,
              entityId: document.fullPath,
              eventType: "account-document-meta",
              fieldName: "active",
              oldValue: true,
              newValue: null,
            });
            break;
          }
          case "archive": {
            if (
              confirm("Are you sure you want to archive this company document?")
            ) {
              await store.dispatch("saveFileMeta", {
                filePath: document.fullPath,
                meta: {
                  customMetadata: {
                    active: null,
                    archive: true,
                  },
                },
              });
              store.dispatch("addCompanyChangelog", {
                accountId: company.value.id,
                entityId: document.fullPath,
                eventType: "account-document-meta",
                fieldName: "archive",
                oldValue: null,
                newValue: true,
              });
            }
            break;
          }
        }
        refreshDocuments();
      },
      companyDocuments: computed(() => {
        return companyDocuments.value
          .map((doc) => {
            return {
              ...doc,
              updated: moment.utc(doc.updated).format("yyyy-MM-DD"),
            };
          })
          .filter((doc) =>
            doc.customMetadata ? doc.customMetadata?.archive !== "true" : true,
          );
      }),
      companyDocumentUpload,
      episodes: computed(() => {
        return episodes.value.map((episode) => {
          const vehicle = vehicles.value.find(
            ({ entityId }) => entityId === episode.vehicle,
          );
          const reservation = reservations.value.find(
            ({ entityId }) => entityId === episode.reservation,
          );
          const renter = renters.value.find(
            ({ entityId }) => entityId === reservation.renter,
          );
          return {
            ...episode,
            episodeId: (episode.id || "").toUpperCase(),
            vehicle: vehicle ? vehicle.key || vehicle.vin : "",
            reservationNumber: reservation ? reservation.externalId : "",
            renterFullName: renter
              ? `${renter.firstName} ${renter.lastName}`
              : "",
            startDate: `${moment
              .utc(episode.startDate)
              .format("MM/DD/yyyy hh:mma")}`,
            endDate: ` ${moment
              .utc(episode.endDate)
              .format("MM/DD/yyyy hh:mma")}`,
          };
        });
      }),
      selectEpisode(episodeId) {
        router.push({
          name: "EpisodeDetail",
          params: {
            companyId: company.value.id,
            episodeId,
          },
        });
      },
      episodeColumns: [
        { name: "id", hidden: true },
        { name: "entityId", hidden: true },
        { name: "Reservation", id: "reservationNumber" },
        { name: "Renter", id: "renterFullName" },
        { name: "Vehicle", id: "vehicle" },
        { name: "Start Date & Time", id: "startDate" },
        { name: "End Date & Time", id: "endDate" },
      ],
      changelogRefresh,
      insuranceDocuments: computed(() => {
        function getDocumentTypeName(docType) {
          switch (docType) {
            case "policy": {
              return "Policy";
            }
            case "certificate-of-insurance": {
              return "Certificate of Insurance";
            }
            case "insurance-card": {
              return "Insurance Card";
            }
            default: {
              return null;
            }
          }
        }
        function getDocumentNumber(doc) {
          switch (doc.docType) {
            case "policy": {
              return doc.policyNumber;
            }
            case "certificate-of-insurance": {
              return doc.certificateNumber;
            }
            case "insurance-card":
            default: {
              return (doc.id || "").toUpperCase();
            }
          }
        }
        return insuranceDocuments.value.map((doc) => {
          return {
            ...doc,
            docType: getDocumentTypeName(doc.docType),
            documentId: getDocumentNumber(doc),
            fileName: (doc.filePath || "").split("/").pop(),
            created: moment.utc(doc.created).format("MM/DD/yyyy"),
          };
        });
      }),
      selectInsuranceDocument(insuranceDocumentId) {
        if (event.ctrlKey || event.metaKey) {
          const routeRes = router.resolve({
            name: "InsuranceDocumentDetail",
            params: {
              companyId: route.params.id,
              documentId: insuranceDocumentId,
            },
          });
          window.open(routeRes.fullPath, "_blank");
        } else {
          router.push({
            name: "InsuranceDocumentDetail",
            params: {
              companyId: route.params.id,
              documentId: insuranceDocumentId,
            },
          });
        }
      },
      insuranceDocumentColumns: [
        { name: "id", id: "id", hidden: true },
        { name: "Document ID", id: "documentId" },
        { name: "File", id: "fileName" },
        { name: "Document Type", id: "docType" },
        { name: "Created", id: "created" },
      ],
      editSubscriptionSpots,
      carAccountType: computed(() => {
        return process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"];
      }),
      truckingAccountType: computed(() => {
        return process.env["VUE_APP_ACCOUNT_TYPE_TRUCKING"];
      }),
      async refreshChangelog() {
        changelogRefresh.value = true;
        try {
          const logs = (changelog.value = await store.dispatch(
            "getCompanyChangelog",
            company.value.id,
          ));
          changelog.value = [];
          setTimeout(() => {
            changelog.value = logs;
            changelogRefresh.value = false;
          }, 1000);
        } catch (err) {
          toast("Failed to get changelogs.");
          setTimeout(() => (changelogRefresh.value = false), 1000);
        }
      },
      changelog: computed(() => {
        return changelog.value.map((change) => {
          return {
            id: change.id,
            change: getChangelogDescription(change),
            description: ellipsisText(change.description || "", 25),
            email: change.actor,
            created: moment.utc(change.timestamp).local().format("yyyy-MM-DD"),
          };
        });
      }),
      selectChangelog(changeId) {
        const item = changelog.value.find(({ id }) => id === changeId);
        if (item && item.description.trim()) {
          alert(item.description);
        }
      },
      changelogColumns: [
        { name: "id", hidden: true },
        { name: "Change", key: "change" },
        { name: "Description", key: "description" },
        { name: "Changed By", id: "email" },
        { name: "Created", id: "created" },
      ],
      selectDriver(driverId) {
        if (event.ctrlKey || event.metaKey) {
          const route = router.resolve({
            name: "Driver",
            params: { companyId: company.value.id, driverId },
          });
          window.open(route.fullPath, "_blank");
        } else {
          router.push({
            name: "Driver",
            params: { companyId: company.value.id, driverId },
          });
        }
      },
      selectVehicle(vehicleId, event) {
        if (event.ctrlKey || event.metaKey) {
          const route = router.resolve({
            name: "Vehicle",
            params: { companyId: company.value.id, vehicleId },
          });
          window.open(route.fullPath, "_blank");
        } else {
          router.push({
            name: "Vehicle",
            params: { companyId: company.value.id, vehicleId },
          });
        }
      },
      vehicleColumns: [
        { name: "id", hidden: true },
        { name: "entityId", hidden: true },
        { name: "VIN", id: "vin" },
        { name: "Key", id: "key" },
        { name: "Status", id: "status" },
        { name: "Telematics", id: "telematics" },
        { name: "Plate", id: "plate" },
        { name: "Price", id: "subscriptionPrice" },
      ],
      vehicleMissingInfoColumns: [
        { name: "id", hidden: true },
        { name: "entityId", hidden: true },
        { name: "VIN", id: "vin" },
        { name: "Missing Information", id: "missingInfo" },
      ],
      vehicles,
      vehiclesTelematicsInfo,
      isTelematicsLoading,
      vehicleFilter: ref(null),
      vehiclesMissingInfo: computed(() => {
        return vehicles.value
          .filter((v) => v.status !== "Inactive")
          .map((vehicle) => {
            let missingInfo = "";
            if (
              [
                "registrationName",
                "registrationAddressLineOne",
                "registrationState",
                "registrationCity",
                "registrationZipcode",
              ].find((x) => !vehicle[x])
            ) {
              missingInfo = "Missing Registration Information";
            }
            if (
              vehicle.financeCompanyApplicable &&
              [
                "financeCompanyName",
                "financeCompanyAddressLineOne",
                "financeCompanyState",
                "financeCompanyCity",
                "financeCompanyZipcode",
              ].find((x) => !vehicle[x])
            ) {
              missingInfo = "Missing Finance Company Information";
            }
            if (
              ["lotAddressLineOne", "lotCity", "lotState", "lotZipcode"].find(
                (x) => !vehicle[x],
              )
            ) {
              missingInfo = "Missing Lot Information";
            }
            if (
              vehicle.financeCompanyApplicable &&
              vehicle.financeCompanyZipcode?.length !== 5
            ) {
              missingInfo = "Invalid Finance Company Zipcode";
            }
            if (vehicle.lotZipcode?.length !== 5) {
              missingInfo = "Invalid Lot Zipcode";
            }
            if (vehicle.registrationZipcode?.length !== 5) {
              missingInfo = "Invalid Registration Zipcode";
            }
            if (vehicle.vin?.length != 17) {
              missingInfo = "Invalid Vehicle Identification VIN Number";
            }
            return { ...vehicle, missingInfo };
          })
          .filter((v) => v.missingInfo);
      }),
      vehiclesMissingCertificates: computed(() => {
        return vehicles.value.filter((vehicle) => {
          if (vehicleDocuments.value.length <= 0) {
            return false;
          }
          if (vehicle.status !== "Active") {
            return false;
          }
          return !vehicleDocuments.value.find((doc) => {
            if (doc.customMetadata?.active !== "true") {
              return false;
            }
            if (!doc.fullPath.includes(company.value.id)) {
              return false;
            }
            if (!doc.fullPath.includes(vehicle.id)) {
              return false;
            }
            if (doc.customMetadata?.tag !== "certificate-of-insurance") {
              return false;
            }
            return true;
          });
        });
      }),
      addVehicle() {
        store.dispatch("logEvent", { name: "add_vehicle_clicked" });
        router.push({
          name: "Vehicle",
          params: { companyId: company.value.id, vehicleId: "new" },
        });
      },
      driverColumns: [
        { name: "id", hidden: true },
        { name: "entityId", hidden: true },
        { name: "Name", id: "name" },
        { name: "DL Number", id: "license" },
        { name: "Date of Birth", id: "dateOfBirth" },
      ],
      drivers: computed(() => {
        return drivers.value.map((drv) => {
          return {
            ...drv,
            dateOfBirth: drv["dateOfBirth"]
              ? moment(drv["dateOfBirth"]).format("yyyy-MM-DD")
              : "",
            name: `${drv.firstName || ""} ${drv.lastName || ""}`,
          };
        });
      }),
      details: computed(() => {
        return [
          {
            name: "Business Name",
            key: "businessLegalName",
            type: "text",
            tab: "account",
          },
          {
            name: "Business Email",
            key: "businessEmail",
            type: "text",
            tab: "account",
          },
          {
            name: "Business Phone",
            key: "businessPhone",
            type: "text",
            tab: "account",
          },
          {
            name: "EIN/FEIN Number",
            key: "einNumber",
            type: "text",
            tab: "account",
          },
          { name: "MC Number", key: "mcNumber", type: "text", tab: "account" },
          {
            name: "DOT Number",
            key: "dotNumber",
            type: "text",
            tab: "account",
          },
          {
            name: "Operational Since",
            key: "operationalSince",
            type: "date",
            tab: "account",
          },
          {
            name: "Authority Type",
            key: "authorityType",
            type: "text",
            tab: "account",
          },
          { name: "Demo Account", key: "demo", type: "text", tab: "account" },
          {
            name: "Billing Date",
            key: "billingDate",
            type: "date",
            tab: "price",
            disabled: true,
          },
          {
            name: "Services Start Date",
            key: "serviceStart",
            type: "date",
            tab: "price",
          },
          {
            name: "ABI On-Lot Integration",
            key: "abiIntegration",
            type: "text",
            tab: "price",
          },
          {
            name: "Initial Invoice",
            key: "initialInvoice",
            type: "text",
            tab: "price",
          },
          {
            name: "Contact First Name",
            key: "contactFirstName",
            type: "text",
            tab: "contact",
          },
          {
            name: "Contact Last Name",
            key: "contactLastName",
            type: "text",
            tab: "contact",
          },
          {
            name: "Contact Phone",
            key: "contactNumber",
            type: "text",
            tab: "contact",
          },
          {
            name: "Contact Email",
            key: "contactEmail",
            type: "text",
            tab: "contact",
          },
          {
            name: "Contact License",
            key: "contactLicense",
            type: "text",
            tab: "contact",
          },
          {
            name: "Contact Position",
            key: "contactPosition",
            type: "text",
            tab: "contact",
          },
          {
            name: "Modified",
            key: "modified",
            type: "text",
            disabled: true,
            tab: "account",
          },
          {
            name: "Created",
            key: "created",
            type: "text",
            disabled: true,
            tab: "account",
          },
        ]
          .filter(({ tab }) => tab === accountTab.value)
          .filter(({ key }) =>
            key === "billingDate" ? company.value.billingDate : true,
          )
          .filter(({ key }) =>
            key === "initialInvoice"
              ? company.value.status === "Onboarding"
              : true,
          )
          .filter(({ key }) =>
            getFieldsForAccountType(company.value.type).includes(key),
          )
          .map((detail) => {
            switch (detail.key) {
              case "serviceStart": {
                return {
                  ...detail,
                  disabled:
                    company.value.status !== "Onboarding" &&
                    company.value.status !== "Pending Activation",
                };
              }
              default: {
                return detail;
              }
            }
          });
      }),
      businessAddressDetails: [
        {
          name: "Address Line 1",
          key: "businessAddressLineOne",
          type: "text",
          tab: "account",
        },
        {
          name: "Address Line 2",
          key: "businessAddressLineTwo",
          type: "text",
          tab: "account",
        },
        { name: "City", key: "businessCity", type: "text", tab: "account" },
        { name: "State", key: "state", type: "text", tab: "account" },
        {
          name: "Zipcode",
          key: "businessZipcode",
          type: "text",
          tab: "account",
        },
      ],
      garagingAddressDetails: [
        {
          name: "Address Line 1",
          key: "garagingAddressLineOne",
          type: "text",
          tab: "account",
        },
        {
          name: "Address Line 2",
          key: "garagingAddressLineTwo",
          type: "text",
          tab: "account",
        },
        { name: "City", key: "garagingCity", type: "text", tab: "account" },
        { name: "State", key: "garagingState", type: "text", tab: "account" },
        {
          name: "Zipcode",
          key: "garagingZipcode",
          type: "text",
          tab: "account",
        },
      ],
      stripeCustomerLink: computed(() => {
        if (company.value.stripeId) {
          return `${process.env["VUE_APP_STRIPE_URL"]}/customers/${company.value.stripeId}`;
        }
        return null;
      }),
      stripeTestCustomerLink: computed(() => {
        if (company.value.stripeTestId) {
          return `${process.env["VUE_APP_STRIPE_URL"]}/test/customers/${company.value.stripeTestId}`;
        }
        return null;
      }),
      salesforceLink: computed(() => {
        if (company.value.salesforceId) {
          return `https://lula.lightning.force.com/lightning/r/Account/${company.value.salesforceId}/view`;
        }
        return null;
      }),
      editStripeId,
      readyToGoActive: computed(() => {
        if (
          company.value.type ===
            process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"] &&
          !company.value.defaultPolicy
        ) {
          if (!(confirmActivation.value.autoCharge === null)) {
            return false;
          }
        }
        if (
          currentCompanyStatus.value !== "Onboarding" &&
          currentCompanyStatus.value !== "Hold"
        ) {
          return false;
        }
        if (!company.value.serviceStart) {
          return false;
        }
        if (!company.value.stripeId) {
          return false;
        }
        if (
          isNaN(company.value.vehicleSubscriptionPrice) ||
          !company.value.vehicleSubscriptionPrice
        ) {
          return false;
        }
        return true;
      }),
      async recreateInvoice() {
        toast("Recreating initial invoice.");
        try {
          const response = await store.dispatch("activateAccount", {
            companyId: company.value.id,
          });
          company.value = await store.dispatch("getCompany", company.value.id);
          if (response.success) {
            toast("Initial invoice recreated.");
          } else {
            toast(response.message || "Failed to recreate inital invoice.");
          }
        } catch (err) {
          toast("Failed to recreate initial invoice.");
        }
      },
      async disconnectTelematicsIntegration(provider) {
        toast("Disconnecting Telematics Integration...");
        try {
          const response = await store.dispatch(
            "disconnectTelematicsIntegration",
            {
              tenantId: company.value.entityId,
              provider: provider,
            },
          );

          if (response.success) {
            toast("Telematics Provider is disconnected.");
            refreshTelematicsProviderStatus();
          } else {
            toast(
              response.message || "Failed to disconnect Telematics Provider.",
            );
          }
        } catch (err) {
          toast("Failed to disconnect Telematics Provider.");
        }
      },
      editComments,
      confirmActivation,
      async activateAccount() {
        if (!confirmActivation.value.confirm) {
          confirmActivation.value.confirm = true;
          return;
        }
        const autoCharge =
          typeof confirmActivation.value.autoCharge === "boolean"
            ? confirmActivation.value.autoCharge
            : company.value.autoCharge;
        if (autoCharge) {
          const pendingVehicles = vehicles.value.filter(
            (v) => v.status === "Pending",
          );
          const invoiceAmount = (
            parseFloat(metrics.value.proratedAmount) * pendingVehicles.length
          ).toFixed(2);
          if (
            !confirm(
              `Activating this account will generate a charge of $${invoiceAmount} to the customer’s payment method on file, are you sure you want to continue?`,
            )
          ) {
            return;
          }
        }
        toast("Activating account.");
        try {
          const response = await store.dispatch("activateAccount", {
            companyId: company.value.id,
            effectiveDate: confirmActivation.value.effectiveDate
              ? moment(confirmActivation.value.effectiveDate, "yyyy-MM-DD")
                  .utc()
                  .format()
              : moment().utc().format(),
            autoCharge,
          });
          if (response.success) {
            window.location.reload();
          } else {
            toast(response.message || "Failed to activate account.");
          }
        } catch (err) {
          toast("Failed to activate account.");
        }
      },
      async saveCompany() {
        if (currentCompanyStatus.value !== company.value.status) {
          if (
            company.value.status === "Hold" ||
            company.value.status === "Inactive"
          ) {
            if (!company.value.statusReason) {
              alert("Must specify a reason for the account status change.");
              return;
            }
          }
        }
        if (company.value.status === "Onboarding") {
          if (
            company.value.type === process.env["VUE_APP_ACCOUNT_TYPE_TRUCKING"]
          ) {
            const requiredOnboardingFields = [
              "businessAddressLineOne",
              "dotNumber",
              "contactFirstName",
              "contactLastName",
              "contactEmail",
              "contactNumber",
              "vehicleSubscriptionPrice",
              "episodePrice",
              "episodePrice",
              "authorityType",
            ];
            if (requiredOnboardingFields.find((x) => !company.value[x])) {
              alert("Company missing information.");
              return;
            }
            if (
              !vehicles.value.find(
                (x) => x.insuranceCriteriaStatus === "Approved",
              )
            ) {
              alert("At least one approved vehicle required.");
              return;
            }
          }
        }
        if (company.value.status !== "Inactive") {
          if (
            company.value.type ===
            process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"]
          ) {
            if (!company.value.defaultPolicy) {
              alert("At least one policy must be selected.");
              return;
            }
          }
        }
        if (company.value.status === "Active") {
          if (!company.value.stripeId) {
            alert("Stripe id must be set.");
            return;
          }
          if (
            isNaN(company.value.vehicleSubscriptionPrice) ||
            !company.value.vehicleSubscriptionPrice
          ) {
            alert("Account vehicle subscription price must be set.");
            return;
          }
          if (
            company.value.type ===
            process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"]
          ) {
            if (!company.value.defaultPolicy) {
              alert("Default policy must be set.");
              return;
            }
          }
        }

        try {
          let contactEmail;
          if (company.value.status === "Inactive") {
            //upon setting account to inactive - change contactEmail to include inactive as an email alias
            const hasPlus = company.value.contactEmail.includes("+");
            const newEmail = hasPlus
              ? company.value.contactEmail.replace("@", "-inactive@")
              : company.value.contactEmail.replace("@", "+inactive@");
            contactEmail = newEmail;
          } else {
            contactEmail = company.value.contactEmail;
          }

          const savedCompany = await store.dispatch("saveCompany", {
            ...company.value,
            contactEmail,
            operationalSince: company.value.operationalSince
              ? moment(company.value.operationalSince, "yyyy-MM-DD")
                  .utc()
                  .format()
              : null,
          });
          editStripeId.value = false;
          company.value = savedCompany;
          currentCompanyStatus.value = company.value.status;
          newPolicy.value.toggle = false;
          toast("Company saved.");
        } catch (err) {
          toast("Failed to save company.");
        }
      },
      togglePolicy(policy) {
        if (!newPolicy.value.toggle) {
          router.push({
            name: "Policy",
            params: { policyId: policy.id },
          });
        }
        const defaultPolicies = (company.value.defaultPolicy || "")
          .split(",")
          .map((id) => policies.value.find((p) => p.entityId === id))
          .filter((p) => {
            if (!p) {
              return false;
            }
            if (
              policy.coverageUse === "on-rent" ||
              policy.coverageUse === "off-rent"
            ) {
              return p.coverageUse !== "traditional";
            }
            if (policy.coverageUse === "traditional") {
              return p.coverageUse === "traditional";
            }
            return true;
          });
        if (defaultPolicies.map((p) => p.entityId).includes(policy.entityId)) {
          company.value.defaultPolicy = defaultPolicies
            .map((p) => p.entityId)
            .filter((p) => p !== policy.entityId)
            .join(",");
        } else {
          company.value.defaultPolicy = [
            ...defaultPolicies.map((p) => p.entityId),
            policy.entityId,
          ].join(",");
        }
      },
      generateApiKey() {
        toast("Creating API Key.");
        store
          .dispatch("generateApiKey", company.value.id)
          .then((res) => {
            toast("API key created.");
            company.value = res;
          })
          .catch(() => toast("Failed to create API key."));
      },
      async exportVehicles() {
        toast("Exporting Vehicles");
        store.dispatch("exportGrid", {
          type: "vehicle",
          companyId: company.value.id,
          strict: true,
        });
      },
      vehicleImport,
      importVehicles() {
        if (vehicleImport.value) {
          vehicleImport.value.click();
        }
      },
      exportDrivers() {
        toast("Exporting Drivers");
        store.dispatch("exportGrid", {
          type: "driver",
          companyId: company.value.id,
        });
      },
      async saveVehicles() {
        toast("Saving vehicles...");
        const response = await store.dispatch("saveVehicles", {
          accountId: company.value.id,
          vehicles: bulkVehicles.value,
          strict: true,
        });
        if (response.success) {
          bulkVehicles.value = [];
          window.location.reload();
        } else {
          toast("Failed to save vehicles.");
        }
      },
      async applicationApprove(isApproved, id) {
        toast("Saving Application Approval...");

        const url = window.location.host.includes("staging")
          ? "https://onboarding.getaddify-staging.com"
          : "https://onboarding.getaddify.com";

        const response = await axios({
          method: "POST",
          headers: {
            Authorization: `Bearer ${await store.dispatch("getIdentityToken")}`,
          },
          data: {
            applicationApproved: isApproved,
          },
          url: url + "/api/account/" + id + "/application",
        });

        if (response.data.status.success) {
          window.location.reload();
        } else {
          toast("Failed to save Application Approval.");
        }
      },
      async manualInvoice(test) {
        if (confirm("Are you sure you want to invoice this customer?")) {
          await store.dispatch("manualInvoice", {
            accountId: company.value.id,
            test,
          });
          toast(`Running manual ${test ? "test invoice" : "invoice"}.`);
        }
      },
      additionalUsers,
      async addAdditionalUser() {
        const email = prompt("User Email:");
        if (!email) {
          return;
        }
        toast("Adding User");
        try {
          const result = await store.dispatch("addAdditionalUser", {
            email,
            accountId: company.value.id,
          });
          if (result.success) {
            additionalUsers.value = await store.dispatch(
              "getAdditionalUsersForAccount",
              company.value.id,
            );
            toast.clear();
            toast("Added User");
          } else {
            toast.clear();
            toast(result.message);
          }
        } catch (ex) {
          toast.clear();
          toast("Problem adding user.");
          throw ex;
        }
      },
      async removeAdditionalUser(email) {
        toast("Removing User");
        try {
          const result = await store.dispatch("removeAdditionalUser", {
            email,
          });
          if (result.success) {
            additionalUsers.value = await store.dispatch(
              "getAdditionalUsersForAccount",
              company.value.id,
            );
            toast.clear();
            toast("Removed User");
          } else {
            toast.clear();
            toast(result.message);
          }
        } catch (ex) {
          toast.clear();
          toast("Problem removing user.");
          throw ex;
        }
      },
      formatEIN: () => {
        const ein = (company.value.einNumber || "").trim();
        company.value.einNumber = formatEin(ein);
      },
      async downloadLossRunReport() {
        try {
          if (downloadingLossRuns.value === true) return;
          toast("Downloading loss runs report.", {
            timeout: 40000,
          });
          downloadingLossRuns.value = true;
          if (!store.state.claimsClient)
            await store.dispatch("initClaimsClient");
          const lossRunReport = await store.dispatch("getLossRunReport", {
            tenantId: company.value.entityId,
          });
          const download = await axios({
            url: lossRunReport.url,
            responseType: "blob",
          });
          const downloadUrl = URL.createObjectURL(download.data);
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", "lossRunReport.zip");
          document.body.appendChild(link);
          toast.clear();
          link.click();
          downloadingLossRuns.value = false;
        } catch (ex) {
          downloadingLossRuns.value = false;
          toast.clear();
          toast("Problem downloading loss runs report.");
          throw ex;
        }
      },
      minServiceStartDate: moment().add(1, "days").format("YYYY-MM-DD"),
      maxServiceStartDate: moment().add(30, "days").format("YYYY-MM-DD"),
      userAccountsRef,
      allPermissionsRef,
      selectedUserAccountRef,
      selectedUserAccountEditingRef,
      selectedUserAccountAccessLevelRef,
      selectedUserAccountAccessLevelDraftRef,
      newUserAccountCreatingRef,
      newUserAccountAccessLevelRef,
      newUserAccountEmailAddressRef,
      onAccessLevelTypeClicked,
      onAddUserAccountSelected,
      onBulkPermissionsSelect,
      onPermissionClicked,
      discardPermissionChanges,
      discardUserAccountCreation,
      savePermissionChanges,
      addUserAccount,
      deleteUserAccount,
      routeToUser,
    };
  },
};
</script>
