import camelCase from "camelcase";
import moment from "moment";

function exportToCsv(filename, rows, columns = []) {
  const processRow = function (row, keys) {
    let finalVal = "";
    Object.keys(keys).forEach((key, j) => {
      const value = row[key];
      let innerValue = !value ? "" : value.toString();
      if (value instanceof Date) {
        innerValue = value.toLocaleString();
      }
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ",";
      finalVal += result;
    });
    return finalVal + "\n";
  };

  let csvFile = "";
  const fileKeys = {};
  columns.forEach((col) => (fileKeys[col] = col));
  rows.forEach((row) => {
    Object.keys(row)
      .filter((key) => (columns.length ? columns.includes(key) : true))
      .forEach((key) => {
        fileKeys[key] = camelCase(key);
      });
  });
  csvFile += processRow(fileKeys, fileKeys);
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i], fileKeys);
  }

  const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

function getChangelogDescription({
  eventType,
  entityId,
  fieldName,
  oldValue,
  newValue,
  description,
}) {
  if (eventType) {
    const defaultDescription = `${fieldName} was changed to ${newValue} ${oldValue ? `from ${oldValue}` : ""} `;
    switch (eventType) {
      case "vehicle-modified": {
        switch (fieldName) {
          case "status": {
            return `Status set to ${newValue} ${oldValue ? `from ${oldValue}` : ""}`;
          }
          case "insuranceCriteriaStatus": {
            return `Insurance Criteria Status set to ${newValue} ${oldValue ? `from ${oldValue}` : ""} 
            ${newValue === "Declined" && description ? ` --  ${description}` : ""}`;
          }
          case "override": {
            return `Vehicle override ${newValue === null ? "unset" : `set to $${parseFloat(newValue).toFixed(2)}`}`;
          }
          default: {
            return defaultDescription;
          }
        }
      }
      case "account-modified": {
        switch (fieldName) {
          case "spots": {
            return `Subscription spots set to ${newValue} ${oldValue ? `from ${oldValue}` : ""}`;
          }
          case "status": {
            return `Account status set to ${newValue} ${oldValue ? `from ${oldValue}` : ""}`;
          }
          case "roadsideAssistancePrice": {
            return `Roadside assistance ${newValue === null ? "removed" : `set to $${parseFloat(newValue).toFixed(2)}`}`;
          }
          case "vehicleSubscriptionPrice": {
            return `Vehicle subscription price ${newValue === null ? "removed" : `set to $${parseFloat(newValue).toFixed(2)}`}`;
          }
          default: {
            return defaultDescription;
          }
        }
      }
      case "vehicle-document-upload": {
        return `Vehicle document (${newValue}) was uploaded`;
      }
      case "vehicle-document-meta": {
        const fileName = entityId.split("/").pop();
        switch (fieldName) {
          case "active": {
            return `${fileName} made ${newValue ? "active" : "inactive"}`;
          }
          case "archive": {
            return `${fileName} was ${newValue ? "archived" : "unarchived"}`;
          }
          case "tag": {
            return `${fileName} was tagged with ${newValue}`;
          }
          default: {
            return defaultDescription;
          }
        }
      }
      case "vehicle-subscription-status": {
        switch (fieldName) {
          case "archive": {
            return `Effective period (${entityId}) removed`;
          }
          case "vehicleRegistrationState": {
            return `Vehicle registration state (${entityId}) changed from ${oldValue} to ${newValue}`;
          }
          default: {
            return `Effective period (${entityId}) ${fieldName} set to ${moment.utc(newValue).format("yyyy-MM-DD")}`;
          }
        }
      }
      default: {
        return defaultDescription;
      }
    }
  }

  return "";
}

function getCoveragesFromPolicy(policyNum) {
  const mfrPolicies = [
    "NKM-ORMSII-1-22-NC-1",
    "NKM-ORMSII-1-22-NC-4",
    "NKM-ORMSII-1-22-NC-5",
    "NKM-ORMSII-1-22-NC-6",
    "NKM-ORMSII-1-22-NC-7",
  ];
  const orpPolicies = ["NKM-ORMSII-1-ORP-22-NC-1", "NKM-ORMSII-1-ORP-22-NC-2"];
  const apdPolicies = ["ORMSII-1-22-NC"];
  const excessPolicies = ["NKM-ORMSII-1-22-NC-2", "NKM-ORMSII-1-22-NC-7-X"];

  if (mfrPolicies.includes(policyNum)) {
    return ["MFR"];
  } else if (orpPolicies.includes(policyNum)) {
    return ["Off-Rent APD", "Off-Rent MFR"].concat();
  } else if (apdPolicies.includes(policyNum)) {
    return ["APD"];
  } else if (excessPolicies.includes(policyNum)) {
    return ["500k"];
  } else return null;
}

function ellipsisText(text, length = 25) {
  if ((text || "").length > length) {
    return `${text.substr(0, length)}...`;
  }
  return text;
}

function isEmptyOrNaN(value) {
  if (value === 0) {
    return false;
  }
  return (value || "").toString().trim() === "" || isNaN(value);
}

function getAccountTypes() {
  return [
    process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"],
    process.env["VUE_APP_ACCOUNT_TYPE_TRUCKING"],
  ].filter((x) => x);
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
}

function getMegabytesFromBytes(sizeInBytes) {
  return (sizeInBytes / (1024 * 1024)).toFixed(2);
}

// formatEin accepts a string and attempts to convert it into the format XX-XXXXXXX.
function formatEin(einString) {
  const ein = (einString || "").trim();

  if (/^[0-9]{2}-[0-9]{7}$/.test(ein)) {
    return ein;
  }

  if (/^[0-9]{9}$/.test(ein)) {
    return `${ein.substring(0, 2)}-${ein.substring(2, 10)}`;
  }

  // Can't format a non-matching string.
  return einString;
}

export {
  exportToCsv,
  getChangelogDescription,
  ellipsisText,
  isEmptyOrNaN,
  getAccountTypes,
  getBase64,
  getMegabytesFromBytes,
  formatEin,
  getCoveragesFromPolicy,
};
